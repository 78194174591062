<template>
  <button @click="throwError">Throw error</button>
</template>

<script>
export default {
  name: "test",
  methods: {
    throwError() {
      throw new Error('Sentry Error');
    }
  }
}
</script>

<style scoped>

</style>
