<template>
    <div :class="container_class">
        <select v-model="target" :class="select_class" :disabled="loading">
            <option :value="null" v-if="nullable">Nessuna selezione</option>
            <option :value="typeof id === 'string' ? v[id] : id(v)" v-for="v in values">{{ typeof label === 'string' ? v[label] : label(v) }}</option>
        </select>
        <button v-if="show_reload"><i class="fa-solid fa-sync" :class="{'animate animate-spin': loading}" @click="load"></i></button>
    </div>
</template>

<script>
export default {
    name: "api-select",
    props: {
        value: String | Number | Object | null,
        route: {
            type: String,
            required: true
        },
        nullable: {
            type: Boolean,
            default: false
        },
        id: {
            type: String | Function,
            default: 'id'
        },
        label: {
            type: String | Function,
            default: 'name'
        },
        select_class: {
            type: String,
            default: ''
        },
        container_class: {
            type: String,
            default: 'flex space-x-1.5'
        },
        show_reload: {
            type: Boolean,
            default: true,
        }
    },
    watch: {
        route: {
            immediate: true,
            async handler() {
                await this.load();
            }
        }
    },
    computed: {
        target: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        async load() {
            if (this.loading) return;
            this.loading = true;
            this.values = (await axios.get(this.route)).data;
            if (!this.nullable && !this.value && this.values.length > 0) this.$emit('input', typeof this.id === 'string' ? this.values[0][this.id] : this.id(this.values[0]));
            this.loading = false;
        }
    },
    data: () => ({
        values: [],
        loading: false
    }),
}
</script>