<template>
    <div class="container rounded bg-white p-2 relative lg:flex-grow flex flex-col space-y-3 h-full">
        <div class="flex flex-col flex-grow lg:h-full overflow-y-auto">
            <div class="flex flex-col lg:flex-row h-full space-y-2 lg:space-y-0 lg:space-x-2">
                <div class="overflow-y-auto flex flex-col space-y-2 flex-grow lg:w-2/3 p-1">
                    <order-general :order="order" :payments="payments"
                                   :branches="branches" :invoicing_branches="invoicing_branches" :load="load"
                                   :update="update"></order-general>
                    <label for="notes">Note</label>
                    <textarea id="notes" v-model="notes"/>
                    <div class="flex space-x-2" v-if="shipments && shipping_policies">
                        <button
                                class="w-1/2 rounded border p-1 flex flex-col items-center justify-center text-center"
                                v-if="Object.keys(shipping_policies).includes('SPLIT')"
                                @click="update_shipping_policy(shipping_policies.SPLIT)"
                                :class="order.shipping_policy === shipping_policies.SPLIT ? 'border-primary' : 'border-gray-light'">
                            <span class="font-bold text-primary">Al più presto</span>
                            <span class="text-gray-dark">Spedisci gli articoli il prima possibile, creando più spedizioni</span>
                        </button>
                        <button
                                class="w-1/2 rounded border p-1 flex flex-col items-center justify-center text-center"
                                v-if="Object.keys(shipping_policies).includes('COMBINE')"
                                @click="update_shipping_policy(shipping_policies.COMBINE)"
                                :class="order.shipping_policy === shipping_policies.COMBINE ? 'border-primary' : 'border-gray-light'">
                            <span class="font-bold text-primary">Unica spedizione</span>
                            <span class="text-gray-dark">Spedisci gli articoli insieme in un'unica spedizione</span>
                        </button>
                    </div>
                    <div class="flex flex-col space-y-1 p-1 text-gray-dark" v-if="shipments"
                         v-for="shipment in shipments">
            <span class="font-bold text-primary">Spedizione il {{
                shipment.shipping_date
                }}, Totale: {{ shipment.total }}</span>
                        <div class="mt-1">
                            <table class="w-full text-sm">
                                <tr class="text-gray-dark w-full">
                                    <th class="text-left">SKU</th>
                                    <th class="text-left hidden md:block px-1.5 max-w-[22rem] xl:max-w-full">
                                        Descrizione
                                    </th>
                                    <th class="text-right">Quantità</th>
                                    <th class="text-right">Prezzo</th>
                                    <th class="text-right">Totale</th>
                                </tr>
                                <tr v-for="product in  shipment.products">
                                    <td class="text-left">{{ product.sku }}</td>
                                    <td class="text-left hidden md:block px-1.5 max-w-[22rem] xl:max-w-full overflow-hidden text-ellipsis">
                                        {{ product.description }}
                                    </td>
                                    <td class="text-right">{{ product.quantity }}</td>
                                    <td class="text-right flex-grow">{{ product.unit_price }}<span
                                            class="text-xs">/{{ product.base_uom }}</span></td>
                                    <td class="text-right">{{ product.line_price }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="border rounded p-2 text-white flex flex-col text-gray-dark"
                         v-if="unavailable_products && unavailable_products.length">
                        <span class="text-md text-secondary">Attenzione!</span>
                        <span class="text-gray-dark">Alcuni articoli non sono più disponibili nella quantità desiderata, pertanto l'ordine è stato modificato come segue:</span>
                        <span v-for="un_product in unavailable_products" class="text-gray-dark">
                            <span class="hidden lg:block">La quantità del prodotto con sku <span
                                    class="font-bold">{{ un_product.sku }}</span> da {{
                                un_product.requested
                                }}{{ un_product.base_uom }} è stata modificata a {{
                                un_product.available
                                }}{{ un_product.base_uom }}</span>
                            <span class="lg:hidden">Qt.a Prodotto <span class="font-bold">{{ un_product.sku }}</span>: {{
                                un_product.requested
                                }}{{ un_product.base_uom }} -> {{ un_product.available }}{{
                                un_product.base_uom
                                }}</span>
                        </span>
                    </div>

                </div>
                <div class="flex-grow lg:w-1/3 p-1">
                    <div
                            class="flex flex-col rounded bg-primary text-white bg-gradient-to-b from-primary-light to-primary rounded lg:ml-sm text-white p-2 space-y-1">
                        <span class="text-md font-bold">{{ order.pricing.total }}</span>
                        <span class="flex justify-between">
                            <span class="font-bold">Totale listino</span>
                            <span class="">{{ order.pricing.base_price }}</span>
                        </span>
                        <span class="flex justify-between">
                            <span class="font-bold">Sconto applicato</span>
                            <span class="">- {{ order.pricing.customer_discount }}</span>
                        </span>
                        <span class="flex justify-between">
                            <span class="font-bold">Spese di spedizione</span>
                            <span class="">{{ order.pricing.shipping_fee }}</span>
                        </span>
                        <span class="flex justify-between">
                            <span class="font-bold">Imponibile</span>
                            <span class="">{{ order.pricing.final_price }}</span>
                        </span>
                        <span class="flex justify-between">
                            <span class="font-bold">IVA</span>
                            <span class="">{{ order.pricing.tax }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="h-sm"></div>
        <div class="block fixed inset-x-0 bottom-0 z-10 bg-white text-gray-dark p-2">
            <div class="flex justify-between items-center ">
                <span class="underline hover:no-underline text-gray-dark hover:text-primary cursor-pointer"
                      @click="sendQuote">Invia Preventivo</span>
                <div class="flex space-x-2">
                    <button class="btn btn-primary" @click="changeStep(1)">Indietro</button>
                    <button class="btn btn-primary" @click="create">Crea</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import OrderGeneral from "./order-general.vue";

export default {
    name: "order-summary",
    components: {OrderGeneral},
    props: {
        order: null,
        changeStep: null,
        update: null,
        load: null,
        payments: null,
        branches: null,
        invoicing_branches: null
    },
    data: () => ({
        unavailable_products: [],
        shipments: null,
        shipping_policies: null,
        notes: null,
    }),
    mounted() {
        let l = this.$loading.show()
        this.$props.update(false);
        this.init();
        l.hide();
    },
    methods: {
        async update_shipping_policy(policy) {
            if (this.$props.order.shipping_policy !== policy) {
                let l = this.$loading.show();
                this.$props.order.shipping_policy = policy;
                this.unavailable_products = await this.$props.update(false);
                let response = (await axios.get('/ordini/orders/' + this.$props.order.id + '/shipments'));
                this.shipments = response.data.data.shipments;
                this.shipping_policies = response.data.data.shipping_policies;
                this.$props.load();
                l.hide();
            }
        },
        async init() {
            this.unavailable_products = (await axios.post('/ordini/orders/' + this.$props.order.id + '/validate')).data;
            let response = (await axios.get('/ordini/orders/' + this.$props.order.id + '/shipments'));
            this.shipments = response.data.data.shipments;
            this.shipping_policies = response.data.data.shipping_policies;
            this.$props.load();
            l.hide();
        },
        async create() {
            let l = this.$loading.show();
            try {
                let response = (await axios.post('/ordini/orders/' + this.$props.order.id + '/upgrade', {notes: this.notes})).data;
                if (typeof response === 'number') {
                    await this.$router.replace({name: 'orders.show', params: {id: response}});
                } else if (Array.isArray(response)) this.unavailable_products = response;
            } catch (e) {
                this.$toast.error(e.response.data.message);
            }
            l.hide();
        },
        async sendQuote() {
            let l = this.$loading.show();
            try {
                (await axios.post('/ordini/orders/' + this.$props.order.id + '/quote'));
                this.$toast.success('Preventivo inviato');
            } catch (e) {
                this.$toast.error('Il cliente non ha utenti associati');
            }
            l.hide();
        }
    }
}
</script>

<style scoped>

</style>
