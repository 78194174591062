<template>
    <div class="mt-2 p-1.5 sm:container">
        <div class="flex justify-between fixed bg-white inset-x-0 top-0 z-10 text-gray-dark text-xs p-2 space-x-3">
            <div class="flex space-x-3">
                <router-link :to="{name: 'menu'}" class="text-md text-gray-dark cursor-pointer"><i
                        class="fa-solid fa-arrow-left"></i>
                </router-link>
                <h1 class="text-md text-gray-dark capitalize">Metadati Prodotti</h1>
            </div>
        </div>
        <div class="h-5"></div>
        <div v-for="m in metadata.list">
            <metadata-show :key="m.id" :metadata="m" :total_products="metadata.total_products"/>
        </div>

        <div class="h-sm"></div>
        <modal ref="report_modal" :close_on_backdrop="false">
            <div class="flex flex-col space-y-1.75 p-1">
                <span class="text-md font-bold text-primary-light">Report</span>
                <span>Aggiornati: {{report.updated}}</span>
                <span>Eliminati: {{report.deleted}}</span>
                <span>Creati: {{report.created}}</span>
                <span>Non modificati: {{report.untouched}}</span>
            </div>
        </modal>
    </div>
</template>

<script>


import MetadataShow from "./metadata-show.vue";
import modal from "../../components/navigation.vue";

export default {
    name: "metadata-index",
    components: {modal, MetadataShow},
    data: () => ({
        metadata: {
            total_products: 0,
            list: null
        },
        report: {}
    }),
    methods: {
        async load() {
            this.metadata = (await axios.get('/metadata')).data;
        },
        show_report(ctx, report) {
            this.report = report;
            ctx.$refs.report_modal.open();
        },
        close_report(){
            this.load();
            this.$refs.report_modal.close();
        }
    },
    mounted() {
        let l = this.$loading.show()
        this.load();
        this.$emitter.on('show_report_emit', (response) => {
            this.$toast.success("Generazione avvenuta con successo");
            this.load();
        });
        l.hide();
    },

}
</script>

