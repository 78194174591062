<template>
    <div class="flex flex-col sm:flex-row sm:items-center justify-between space-x-2">
        <div class="sm:w-1/2 flex items-center space-x-1 cursor-pointer">
            <img :src="item.thumbnail" :alt="'sku '+item.sku" class="h-md">
            <div class="flex flex-col space-y-1 text-sm">
                <span class="text-primary font-bold">SKU {{ item.sku }}</span>
                <span :class="item['availability']['color']"
                      class="font-bold text-left">{{ item.availability.text }}</span>
            </div>
        </div>
        <div class="sm:w-1/2 flex">
            <ic class="flex flex-col flex-grow">
                <label class="text-gray-dark text-xs" for="quantity">Quantità</label>
                <input type="text" v-model="quantity" @focus="$event.target.select()"
                       id="quantity" @change="change_quantity_line">
                <span class="text-primary self-end">{{ item.line_price }}</span>
            </ic>
            <div class="flex flex-col space-y-1">
                <span class="text-gray-dark text-xs">&#8205;</span>
                <span @click="delete_line()" class="btn text-gray hover:bg-white cursor-pointer hover:text-secondary"><i class="fa-solid fa-times"></i></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "cart-line",
    props: {
        item: {},
        change_quantity: null
    },
    data: () => ({
        quantity: 1
    }),
    mounted(){
        this.quantity = this.$props.item.quantity;
    },
    methods:{
        change_quantity_line(){
            this.quantity = Math.ceil(this.quantity / this.$props.item.sale_lot) * this.$props.item.sale_lot;
            this.quantity = this.quantity <= 0 ? this.$props.item.quantity : this.quantity;
            this.$props.change_quantity(this.$props.item, this.quantity);
        },
        delete_line(){
            this.$props.change_quantity(this.$props.item, 0);
        }
    },
    watch:{
        'item.quantity': function(){
            this.quantity = this.$props.item.quantity;
        }
    }
}
</script>

<style scoped>

</style>
