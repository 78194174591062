<template>

</template>

<script>
export default {
  name: "order-filters",
  methods: {
    open() {
      this.$refs.filter.open();
    }
  },
  data: () => ({
    start_data: null,
    end_date: null,
    user_agent_id: null,
    type: null,
    web: null
  }),
}
</script>

<style scoped>

</style>
