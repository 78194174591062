<template>

  <div class="mt-2 p-1.5 lg:container">
    <div>
      <div class="flex justify-between mt-2 mb-1">
        <span class="text-md text-gray-dark">Utenti</span>
        <button @click="create"
                class="btn btn-primary text-xs flex items-center justify-center">
          <i class="fas fa-plus"></i></button>
      </div>
      <div class="bg-white border border-thin border-gray-light rounded relative overflow-x-auto">
        <div v-if="loading"
             class="absolute left-0 top-0 right-0 bottom-0 bg-smoke rounded flex items-center justify-center">
          <span class="text-white text-md">Caricamento <i class="fas text-base fa-circle-notch animate-spin"></i></span>
        </div>

        <table class="table-auto min-w-full">
          <tr class="text-gray-dark">
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light rounded-tl">Nome</th>
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Email</th>
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Telefono</th>
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Accesso abilitato</th>
            <th class="font-normal text-center px-1.5 py-1.5 bg-gray-light rounded-tr"></th>
          </tr>
          <tr v-for="user in users" class="hover:bg-gray-extralight transition">
            <td class="px-1.5 text-sm py-1.5">{{ user.name }}</td>
            <td class="px-1.5 text-sm py-1.5">{{ user.email }}</td>
            <td class="px-1.5 text-sm py-1.5">{{ user.phone }}</td>
            <td class="px-1.5 text-sm py-1.5">{{ user.login_allowed ? 'SI' : 'NO' }}</td>
            <td class="px-1.5 text py-1.5">
              <button @click="select(user.id)"><i class="fas fa-arrow-right"></i></button>
            </td>
          </tr>
        </table>
      </div>

      <slideover ref="slideover" :title="user.id ? 'Modifica utente' : 'Nuovo utente'" :actions="slideover_actions">
        <template v-slot:content>
          <div class="space-y-1.5">
            <ic :e="errors.name">
              <label>Nome</label>
              <input type="text" v-model="user.name">
            </ic>
            <ic :e="errors.email">
              <label>Email</label>
              <input type="text" v-model="user.email">
            </ic>
            <ic :e="errors.phone">
              <label>Telefono</label>
              <input type="tel" v-model="user.phone">
            </ic>
            <ic :e="errors.login_allowed">
              <input id="login_allowed" type="checkbox" v-model="user.login_allowed">
              <label for="login_allowed" class="ml-1">Può accedere al sito</label>
            </ic>
            <button class="btn btn-primary-outline text-sm w-full" v-if="user.id" @click="send_reset_password">
              Invia nuova password via email
            </button>
          </div>
          <div v-if="user_carts" class="flex flex-col space-y-2 space-x-2 mt-3">
            <span class="text-gray-dark capitalize text-lg">Carrelli</span>
            <span v-for="user_cart in user_carts" class="underline text-primary hover:no-underline cursor-pointer md:text-md"
                  @click="open_cart(user_cart)">
                            {{ user_cart.cart_type === 1 ? 'Lista Prenotazioni' : 'Carrello Acquisti' }}
                    </span>
          </div>
        </template>
      </slideover>
      <modal ref="delete_user_modal">
        <div class="flex flex-col space-y-1.75 p-1">
          <span class="text-md font-bold text-primary-light">Attenzione</span>
          <span class="text-gray-dark">Sei sicuro di voler eliminare l'utente {{user.name}}?</span>
          <span class="text-gray-dark">Premendo <span class="text-primary-light">Elimina</span> l'utente {{user.name}} verrà eliminato.</span>
          <div class="flex w-full justify-end">
            <div class="flex space-x-2">
              <button class="btn btn-outline-primary" @click="$refs.delete_user_modal.close()">Annulla</button>
              <button class="btn btn-secondary" @click="destroy">Elimina</button>
            </div>
          </div>
        </div>
      </modal>
      <cart_slide ref="cart" :user="user" :cart="cart"></cart_slide>
    </div>
  </div>
</template>

<script>
import cart_slide from "../../components/cart_slide.vue";

export default {
  name: "users",
  components: {cart_slide},

  props: ['id'],
  computed: {
    slideover_actions: ctx => ctx.user.id
        ? [{name: 'Cancella', callback: () => ctx.$refs.delete_user_modal.open(), class: 'btn-secondary'}, {
          name: 'Aggiorna',
          callback: ctx.update,
          class: 'btn-primary'
        }]
        : [{name: 'Crea utente', callback: ctx.store, class: 'btn-primary'}]
  },
  methods: {
    create() {
      this.user = {
        id: null,
        name: null,
        email: null,
        phone: null,
        login_allowed: false,
      };
      this.$refs.slideover.open();
    },
    async select(id) {
      let loading = this.$loading.show();
      try {
        this.user = (await axios.get('/clienti/users/' + id)).data;
        this.user.login_allowed = !!this.user.login_allowed;
        this.user_carts = (await axios.get('/clienti/users/' + id + '/carts')).data.data;
        this.$refs.slideover.open();
      } catch (e) {
      }
      loading.hide();
    },
    async load() {
      this.loading = true;
      this.users = (await axios.get('/clienti/customers/' + this.id + '/users')).data;
      this.loading = false;
    },
    async store() {
      this.$refs.slideover.set_loading(true);
      try {
        let id = (await axios.post('/clienti/customers/' + this.id + '/users', this.user)).data.id;
        await this.select(id);
        await this.load();
        this.$toast.success('Utente creato correttamente');
      } catch (e) {
        this.errors = e.response.data.errors;
      }
      this.$refs.slideover.set_loading(false);
    },
    async update() {
      this.$refs.slideover.set_loading(true);
      try {
        await axios.put('/clienti/users/' + this.user.id, this.user);
        await this.load();
        this.$toast.success('Utente aggiornato correttamente');
      } catch (e) {
        this.errors = e.response.data.errors;
      }
      this.$refs.slideover.set_loading(false);
    },

    async destroy() {
      this.$refs.slideover.set_loading(true);
      try {
        await axios.delete('/clienti/users/' + this.user.id);
        this.$toast.success('Utente cancellato correttamente');
        this.$refs.slideover.set_loading(false);
        this.$refs.slideover.close();
        await this.load();
        this.$refs.delete_user_modal.close();
      } catch (e) {
        this.$toast.error('Qualcosa è andato storto');
        this.$refs.slideover.set_loading(false);
        this.$refs.delete_user_modal.close();
      }

    },
    async send_reset_password() {
      this.$refs.slideover.set_loading(true);
      try {
        await axios.post('/clienti/users/' + this.user.id + '/send_password');
        this.$toast.success('Password inviata correttamente');
      } catch (e) {
        this.$toast.success('Errore');
      }
      this.$refs.slideover.set_loading(false);
    },
    async open_cart($cart) {
      this.cart = $cart;
      this.$refs.cart.open();
    },
    get_cart() {
      return this.$refs.cart_slideover;
    },
    create_ticket() {
      this.$emitter.emit('open_ticket', {
        flag: 1,
        ticket_class: 'App\\Domain\\Customer\\Models\\Customer',
        id: this.$props.id
      });
    },
  },
  data: () => ({
    loading: true,
    users: [],
    user: {
      id: null,
      name: null,
      email: null,
      phone: null,
      login_allowed: false,
    },
    customer: null,
    errors: {},
    user_carts: {},
    cart: {},
    cart_total: '',
    can_create_order: false,
  }),
  async mounted() {
    let l = this.$loading.show();
    try {
      this.customer = (await axios.get('/clienti/customers/' + this.$props.id)).data;
      l.hide();
    } catch (e) {
      this.$toast.error(e.response.data.message || e);
      l.hide();
      this.$router.push({name: 'customers.index'});
    }
    await this.load();
  }
}
</script>
