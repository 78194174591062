<template>
    <div>
        <p class="text-md text-gray-dark mt-4 mb-1">Condizioni commerciali</p>
        <div class="bg-white border border-thin border-gray-light rounded py-2 space-y-2 px-2">
            <div class="grid grid-cols-2 lg:grid-cols-5 gap-2">
                <div>
                    <label>Listino</label>
                    <input class="disabled" type="text" :value="customer.price_list" readonly disabled>
                </div>
                <div>
                    <label>Tipo Sconto</label>
                    <input class="disabled" type="text" :value="customer.discount_mode" readonly disabled>
                </div>
                <div>
                    <label>Percentuale sconto</label>
                    <input class="disabled" type="text" :value="customer.discount" readonly disabled>
                </div>
                <div>
                    <label>Spese di spedizione</label>
                    <input class="disabled" type="text" :value="customer.shipping_fee" readonly disabled>
                </div>
                <div>
                    <label>Limite spese spedizione</label>
                    <input class="disabled" type="text" :value="customer.shipping_fee_threshold" readonly disabled>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "sales",
    props: ['customer']
}
</script>