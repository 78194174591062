<template>
  <div
      class="h-full bg-white md:shadow-light md:rounded md:transition md:transition-shadow md:hover:shadow-hover flex md:flex-col relative">
    <router-link :to="{name: 'products.show', params : {id: product.id, order_id: order_id}}"
                 class="w-1/3 md:w-full">
      <Transition
          enter-active-class="duration-700 ease-in-out"
          enter-from-class="transform-all opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="transform-all opacity-0"
      >
        <img :src="product.thumbnail" :alt="product.thumbnail_alt" class="p-1.5">
      </Transition>
    </router-link>
    <div class="flex flex-col lg:flex-grow w-2/3 md:w-full">
      <router-link :to="{name: 'products.show', params : {id: product.id, order_id: order_id}}"
                   class="flex md:flex-col md:p-2 md:pt-0 flex-grow">
        <div class="absolute space-y-1 w-full left-0 right-0 top-2 px-2">
          <div v-if="product.promo_price" class="hidden md:block">
            <span class="bg-flags-promo rounded text-white p-1 text-xs">in promo -{{ product.promo_percentage }}%</span>
          </div>
          <div v-if="product.new && !product.arriving" class="block">
            <span class="bg-flags-new rounded text-white p-1 text-xs">novità</span>
          </div>
          <div v-if="!product.base_price && product.arriving" class="block">
            <span class="bg-success rounded text-white p-1 text-xs">In arrivo</span>
          </div>
        </div>
        <div class="flex flex-col flex-grow pl-2 md:pl-0">
          <div v-if="product.promo_price" class="block md:hidden">
            <span class="bg-flags-promo rounded text-white p-1 text-xs">Promo -{{ product.promo_percentage }}%</span>
          </div>
          <div class="flex-grow">
                        <span class="block text-primary font-bold mt-1">
                            SKU {{ product.sku }}
                            <span class="text-gray font-normal text-sm">{{ product.family_code }}</span>
                        </span>
            <span class="block text-gray-dark text-sm line-clamp-3">{{ product.description }}</span>
          </div>
          <div class="mt-2 md:mt-3">
            <template v-if="product.base_price">
              <p class="text-muted block text-sm">Prezzo di listino</p>
              <p v-if="product.promo_price" class="text-muted text-sm block line-through">{{ product.base_price }}</p>
              <p class="whitespace-nowrap text-md font-bold truncate"
                 :class="product.promo_price ? 'text-flags-promo' : 'text-primary'">{{ product.customer_price }}
                <span class="text-xs font-normal"
                      v-if="product.customer_price_division">{{ product.customer_price_division }}</span></p>
              <p v-if="product.promo_price" class="text-sm block mt-1">fino al {{ product.promo_expiration }}</p>
            </template>
            <p class="font-bold text-sm text-success text-center" v-else-if="product.arriving">Prodotto in arrivo</p>
            <p class="font-bold text-sm text-primary-light text-center" v-else-if="product.replacement">Questo prodotto
              ha un nuovo codice</p>
            <p class="font-bold text-sm text-secondary text-center" v-else>Questo prodotto non è disponibile</p>
          </div>
        </div>
      </router-link>
      <router-link
          :to="{name: 'products.show', params : {id: product.replacement && (product.availability.type === 4) ? product.replacement.id : product.id, order_id: order_id}}"
          class="p-2 md:pt-0 flex items-center text-xs">
        <template v-if="product.base_price && (product.availability.type !== 4 || !product.replacement)">
          <div class="space-y-1">
            <p class="font-bold text-start" :class="product.availability.color"
               v-html="product.availability.text"></p>
            <p class="text-[0.7rem] text-gray-dark text-start" v-if="product.raw_quantity">
              (
              <span
                  v-if="product.raw_quantity[1]">IT: {{
                  Math.max(0, product.raw_quantity[1].quantity)
                }} {{ product.base_uom || 'pz' }},  </span>
              <span
                  v-if="product.raw_quantity[2]">EU: {{
                  Math.max(0, product.raw_quantity[2].quantity)
                }} {{ product.base_uom || 'pz' }}</span>)
            </p>
          </div>
        </template>
        <p v-else-if="product.arriving" class="text-gray-dark w-full">Questo prodotto sarà presto disponibile</p>
        <p v-else-if="!product.replacement" class="text-gray-dark w-full">Contatta l'assistenza per maggiori
          informazioni</p>
        <p v-else class="text-primary-light w-full hover:underline">Nuovo codice: <b
            class="ml-0.5">{{ product.replacement.sku }}</b></p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "product-card",
  props: ['product', 'order_id']
}
</script>
