<template>
    <div class="p-2 rounded flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:space-x-4 bg-gray-extralight border-thin border-gray-light">
        <div>
            <h6 class="text-primary font-bold mb-1">Indirizzo di spedizione</h6>
            <div v-if="order.branch_data" class="text-sm">
                <p>{{order.branch_data.recipient}} </p>
                <p>{{order.branch_data.address}}</p>
                <p>{{order.branch_data.city}} {{order.branch_data.zip_code}}</p>
                <p>{{order.branch_data.phone}}</p>
            </div>
            <div v-else class="text-sm">
                <p>Ritiro presso la sede LED Italia</p>
                <p>Via Salvo d'Acquisto 19</p>
                <p>Pozzuolo Martesana 20060 MI</p>
                <p>Lun-Ven 9:00-18:00</p>
            </div>
        </div>
        <div>
            <h6 class="text-primary font-bold mb-1">Pagamento</h6>
            <span class="text-sm">{{order.payment_method}}</span>
            <div class="text-sm mt-1" v-html="order.payment_instructions"></div>
        </div>
        <div class="hidden lg:block flex-grow"></div>
        <div>
            <h6 class="text-primary font-bold mb-1">Riepilogo costi</h6>
            <table class="text-sm w-full text-gray-dark">
                <tr>
                    <td>Totale di listino</td>
                    <td class="text-right pl-3 pb-1">{{order.pricing.base_price}}</td>
                </tr>
                <tr>
                    <td>Spese di spedizione</td>
                    <td class="text-right pl-3 pb-1">{{order.pricing.shipping_fee}}</td>
                </tr>
                <tr>
                    <td>Imponibile</td>
                    <td class="text-right pl-3 pb-1">{{order.pricing.final_price}}</td>
                </tr>
                <tr>
                    <td>IVA</td>
                    <td class="text-right pl-3 pb-1">{{order.pricing.tax}}</td>
                </tr>
                <tr>
                    <td colspan="2" class="py-1">
                        <hr class="text-gray">
                    </td>
                </tr>
                <tr>
                    <td>Totale</td>
                    <td class="text-right pl-3">{{order.pricing.total}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import TrackingDetails from "./tracking-details.vue";

export default {
    name: "order-details",
  components: {TrackingDetails},
    props: ['order']
}
</script>

<style scoped>

</style>
