<template>
  <div class="justify-center items-center w-full min-h-[25rem] max-h-[35rem] flex">
    <Pie ref="pie_agent" v-if="!loading" :options="chartOptions" :data="chartData" :plugins="plugins"/>
    <p v-else class="text-lg"><i class="fa-solid fa-spinner animate-spin"></i></p>
  </div>
</template>

<script>
import {Pie} from 'vue-chartjs'
import {Chart as ChartJS, Tooltip, Legend, ArcElement} from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import '@splidejs/vue-splide/css';

ChartJS.register(Tooltip, Legend, ArcElement)
export default {
  name: "agents_contribute_charts",
  components: {Pie},
  props: ['sales_agent_id'],
  data: () => ({
    plugins: [{
      id: 'legendMargin',
      beforeInit(chart, legend, options){
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit(){
          fitValue.bind(chart.legend)();
          return this.height += 20;
        }
      }
    }],
    loading: true,
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 40
        }
      },
      plugins: {
        datalabels: {
          color: '#292929',
          anchor: 'end',
          align: 'end',
          font:{
            size: 14
          },
          formatter: function(value, context) {
            let sum = context.chart.data.datasets[0].data.reduce((v, p) => v + p, 0);
            let percentage = Math.round(value * 100 / sum);
            return percentage ? percentage + '%' : null;
          }
        }
      }
    },
    chartData: {
      labels: [],
      datasets: [
        {
          backgroundColor: [
            'rgb(255, 99, 132)',
            '#4dc9f6',
            '#f67019',
            '#f53794',
            '#537bc4',
            '#acc236',
            '#166a8f',
            '#00a950',
            '#58595b',
            '#8549ba',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            '#2a2962',
            '#6d90cc',
            '#edd7ff',
            '#c177ff',
            '#ff7858',
            '#094b4b',
            '#0e8ae8',
            '#855f22',
            '#66606e',
            '#6a329f',
            '#5a329F',
            '#6F7FFf',
            '#acFFFF',
            '#Fcc236',
            '#aCc236',
            '#acF23F',
            '#f77379',
            '#fF3794',
            '#f537F4',
            '#fFF794',
            '#E53794',
            '#0eFaeF',
            '#0F8ae8'
          ],
          data: []
        },
      ]
    },
  }),
  async mounted() {
    let data = (await axios.get('/agents')).data;
    data.forEach((v) => this.chartData.labels.push(v.company_name));
    data.forEach((v) => this.chartData.datasets[0].data.push(v.total_orders_float));
    this.plugins.push(ChartDataLabels);
    this.loading = false;
  }
}
</script>

<style scoped>

</style>
