<template>
    <div class="mt-4 px-2">
        <ul class="flex flex-wrap justify-center">
            <li v-if="page > 2" class="cursor-pointer">
                <div @click="changePage(0)"
                     class="rounded m-1 px-2 text-center block transition hover:bg-primary-light hover:text-white bg-gray-light cursor-pointer">
                    1
                </div>
            </li>
            <li v-if="page > 2" class="mx-2 my-1">...</li>
            <li v-for="p in range(Math.max(0, page - 2), Math.min(page + 2, pages - 1))" class="cursor-pointer">
                <div @click="changePage(p)"
                     class="rounded m-1 px-2 text-center block transition hover:bg-primary-light hover:text-white cursor-pointer"
                     :class="page === p ? 'bg-primary text-white' : 'bg-gray-light'">
                    {{ p + 1 }}
                </div>
            </li>
            <li v-if="page + 2 < pages - 1" class="mx-2 my-1">...</li>
            <li v-if="page + 2 < pages - 1" class="cursor-pointer">
                <div @click="changePage(pages -1)"
                     class="rounded m-1 px-2 text-center block transition hover:bg-primary-light hover:text-white bg-gray-light cursor-pointer">
                    {{ pages }}
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "order-pagination",
    props: ['page', 'pages', 'changePages'],
    methods: {
        changePage: function (page) {
            this.$props.changePages(page);
        },
        range: function (start, end) {
            let ans = [];
            for (let i = start; i <= end; i++) {
                ans.push(i);
            }
            return ans;
        }
    }
}
</script>

