<template>
  <div class="flex flex-col space-y-2 bg-white p-2 mt-5" v-if="order">
    <div class="flex fixed bg-white inset-x-0 top-0 z-10 text-gray-dark text-xs p-2 space-x-3">
      <button @click="$router.go(-1)" class="text-md text-gray-dark cursor-pointer"><i
          class="fa-solid fa-arrow-left"></i></button>
      <h1 class="text-md text-gray-dark capitalize">Dettaglio Ordine {{ id }}</h1>
    </div>
    <div class="flex justify-between items-center text-sm">
      <span class="font-bold rounded-xl px-2 py-1 text-white bg-primary" :class="order.status_color">{{
          order.status
        }}</span>
      <div class="flex space-x-2">

        <div v-if="order.attachments" class="menu-trigger relative text-base">
          <button class="font-bold text-primary-light">Allegati <i class="fas fa-angle-down ml-1"></i></button>
          <div
              class="max-h-0 w-auto overflow-hidden absolute right-0 bg-white shadow z-10 menu-container transition-all ease-in-out rounded min-w-max">
            <div class="p-2 px-4">
              <ul class="list-disc">
                <li v-for="(url, name) in order.attachments" :key="url">
                  <a :href="url" class="hover:text-primary" target="_blank">{{ name }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button class="btn btn-secondary" @click="cancel()">Annulla</button>
      </div>

    </div>


    <order-details :order="order"></order-details>

    <div class="p-2 rounded bg-gradient-to-l from-primary-light to-primary text-white shadow-light">
      <h5 class="font-bold mb-1">Allega file all'ordine</h5>
      <div class="flex flex-col md:flex-row space-y-2 md:space-x-2 md:space-y-0 items-center mt-2">
        <input type="file" name="file" class="hidden" id="file" @change="uploadFile">
        <div class="flex w-full flex-grow space-x-2">
          <input type="text" v-model="title" name="title" placeholder="Titolo" class="h-auto flex-grow py-1 bg-white">
          <label for="file" class="text-md text-white"><i class="fa-solid fa-paperclip"></i></label>
        </div>
        <button :class="{disabled: !file || !title}" class="btn btn-outline h-auto py-1 w-full md:w-auto" @click="submitFile">Carica</button>
      </div>
      <span v-if="file" @click="file = null" class="cursor-pointer w-auto"><i class="fa-solid fa-paperclip"></i> {{
          fileName
        }} <i class="fa-solid fa-xmark"></i></span>
    </div>


    <div class="flex flex-col space-y-4">
      <order-shipment v-for="(shipment,index) in order.shipments" :shipment="shipment" :order="order" :key="index"
                      :name="index"></order-shipment>
    </div>
    <tracking-details></tracking-details>
  </div>
</template>

<script>
import OrderDetails from "./order-details.vue";
import OrderShipment from "./order-shipment.vue";
import TrackingDetails from "./tracking-details.vue";

export default {
  name: "order-show",
  components: {TrackingDetails, OrderShipment, OrderDetails},
  props: ['id', 'from'],
  methods: {
    async load(load = true) {
      let l = '';
      if(load) l = this.$loading.show();
      this.order = (await axios.get('/ordini/orders/' + this.$props.id)).data;
      if(load) l.hide();
    },
    cancel() {
      this.$emitter.emit('open_ticket', {
        flag: 1,
        ticket_class: 'App\\Domain\\Checkout\\Models\\Order',
        id: this.$props.id,
        title: 'Cancellazione Ordine ' + this.$props.id,
        message: `Ciao,
vorrei annullare l'ordine ` + this.$props.id + '.'
      });
    },
    uploadFile(e){
      this.file = e.target.files[0];
    },
    async submitFile() {
      let l = this.$loading.show();
      try {
        let formData = new FormData();
        if (this.file) formData.append('file', this.file);
        formData.append('title', this.title);
        await axios.post('/ordini/orders/' + this.$props.id + '/files', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } catch(e){
        this.$toast.error("Formato non valido");
        this.title = null;
        this.file = null;
        return;
      }
      await this.load(false);
      this.title = null;
      this.file = null;
      l.hide()
      this.$toast.success("Allegato inserito correttamente");
    }
  },
  beforeMount() {
    this.load();
  },
  data: () => ({
    order: null,
    file: null,
    title: null,
  }),
  computed: {
    fileName() {
      return this.file ? this.file.name : null;
    }
  }
}
</script>

<style scoped>

</style>
