<template>
    <div class="border-thin border-gray-light rounded">

        <div v-if="!shipment.show_tracking" class="p-2">
            <h1 class="text-sm text-secondary">Data prevista di spedizione: {{shipment.shipping_date}}</h1>
        </div>
        <div v-else-if="!order.is_deleted" class="border-b-thin p-2 border-gray-light">
            <h1 v-if="shipment.trackings_count === 0" class="text-sm text-secondary">Data prevista di spedizione: {{shipment.shipping_date}}</h1>
            <div class="flex flex-col space-y-2">
                <div v-for="tracking in shipment.trackings">
                    <order-tracking :tracking="tracking"></order-tracking>
                </div>
            </div>
        </div>
        <div class="flex flex-col lg:flex-row space-y-4 divide-y-thin divide-gray-light lg:divide-y-0 lg:space-x-4 lg:space-y-0 p-2">
            <div class="flex flex-col flex-grow space-y-2 lg:space-y-1">
                <div v-for="product in shipment.products" class="flex space-x-2">
                    <div class="w-md flex-shrink-0 p-1">
                        <img :src="product.image" alt="" class="rounded">
                    </div>
                    <div>
                        <p class="text-gray-dark font-bold">SKU {{product.sku}}</p>
                        <router-link :to="{name: 'products.show', params: {id: product.id}}" class="text-sm text-primary-light hover:text-primary transition">{{product.description}}</router-link>
                        <p class="text-sm mt-1">
                            Prezzo: {{product.final_price}} - {{product.requested_quantity}}{{product.base_uom}}
                        </p>
                        <p class="text-sm">Totale: {{product.total}}</p>
                    </div>
                </div>
            </div>
            <div class="pt-2">
                <table class="text-sm text-gray-dark w-full">
                    <tr>
                        <td>Volume</td>
                        <td class="pl-2 pb-1 text-right">{{shipment.volume}}</td>
                    </tr>
                    <tr>
                        <td>Peso</td>
                        <td class="pl-2 pb-1 text-right">{{shipment.weight}}</td>
                    </tr>
                    <tr>
                        <td>Spese di spedizione</td>
                        <td class="pl-2 pb-1 text-right">{{shipment.shipping_fee}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

</template>

<script>
import OrderTracking from "./order-tracking.vue";

export default {
    name: "order-shipment",
  components: {OrderTracking},
    props: ['shipment', 'order']
}
</script>

<style scoped>

</style>
