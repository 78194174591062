<template>
  <div>
    <div class="h-md"></div>
    <div class="lg:px-1 container py-2 xl:w-30 lg:w-30 md:w-30 w-full">
      <div class="flex justify-between my-2">
        <img src="/src/assets/vtac-italia.png" alt="" class="h-5 mx-2">
        <img src="/src/assets/led-italia-extended.png" alt="" class="h-5 mx-2">
      </div>
      <div class="flex flex-col bg-white rounded shadow-light px-1 pb-3 items-center" id="login-form">
        <div class="flex flex-col items-center w-full">
          <h1 class="text-title text-gray-dark font-bold my-3 text-center">Piattaforma Agenti</h1>
          <span class="text-center mb-2">Accedi ora al tuo account agente</span>
          <div class="flex flex-col m-2 w-4/5 space-y-2">
            <input type="text" class="bg-gray-light" v-model="email" placeholder="Email"/>
            <input type="password" class="bg-gray-light" v-model="password" placeholder="Password" v-on:keyup.enter="submit"/>
          </div>
          <div class="flex my-2">
            <button class="btn btn-primary h-4 focus:outline-none" @click="submit" :class="{disabled: loading}">
              Accedi
              <i class="fas fa-spinner animate-spin ml-2" v-if="loading"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  name: "login",
  mounted() {
    if (!!this.$attrs.message) this.$toast.success(this.$attrs.message);
  },
  methods: {
    submit: function () {
      this.loading = true;
      axios.post('/login', {
        email: this.email,
        password: this.password,
      }).then(async response => {
        localStorage['key-api'] = response.data.key_api;
        localStorage['key-user'] = response.data.key_user;
        if (localStorage['key-api']) window.axios.defaults.headers.common['Authorization'] = localStorage['key-api'];
        if (localStorage['key-user']) window.axios.defaults.headers.common['X-User-Authorization'] = localStorage['key-user'];
        if (typeof localStorage['is_super_agent'] === 'undefined') {
          this.loading = true;
          localStorage['is_super_agent'] = (await axios.get('/super-agent')).data;
          this.loading = false;
        }
        this.$router.replace({name: 'dashboard'});
        this.$router.push({name: 'dashboard'});
      })
          .catch(error => {
            this.$toast.error("Email o password errata");
            this.loading = false;
          });
    }
  },
  data() {
    return {
      loading: false,
      email: null,
      password: null,
    }
  },
}
</script>

<style scoped>

</style>
