<template>
    <div class="absolute" v-if="o">
        <div class="fixed z-40 top-0 right-0 left-0 bottom-0" style="background-color: #00000040" @click="close(false)"></div>
        <div class="fixed z-40 top-0 right-0 h-full w-full md:w-4/5 lg:w-3/5 xl:w-2/5 2xl:w-1/4 overflow-hidden">
            <div class="relative h-full w-full shadow flex flex-col transform translate-x-full transition-transform" :class="slideover.background" ref="content">
                <div class="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center text-lg backdrop-filter backdrop-blur-sm" :class="slideover.loading_text_color"
                     v-if="this.l">
                    <span class="font-bold pr-2">Caricamento</span>
                    <i class="fa-solid fa-circle-notch animate-spin"></i>
                </div>
                <slot name="body">
                    <slot name="title">
                        <div class="flex justify-between p-2 text-lg items-center">
                            <h1 class="text-gray-dark">{{ title }}</h1>
                            <button v-if="can_close" @click="close(false)"><i class="fa-solid fa-times"></i></button>
                        </div>
                    </slot>
                    <div class="flex-grow p-2 overflow-y-auto">
                        <slot name="content"></slot>
                    </div>
                    <slot name="actions-container">
                        <template v-if="$slots.actions || (actions && actions.length > 0)">
                            <hr :class="slideover.divider_color">
                            <div class="flex justify-end p-2 space-x-2">
                                <slot name="actions"></slot>
                                <button v-for="action in actions" class="btn" :class="action.class" @click="action.callback()">{{ action.name }}</button>
                            </div>
                        </template>
                    </slot>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "slideover",
    props: {
        title: String,
        can_close: {type: Boolean, default: true},
        actions: {type: Array, default: null}
    },
    computed: {
        slideover: ctx => ctx.$components.slideover
    },
    watch: {
        o() {
            if (this.o) {
                document.querySelector('body').style.overflowY = 'hidden';
                this.$emit('open');
            }else {
                document.querySelector('body').style.overflowY = null;
                this.$emit('close');
            }
        }
    },
    methods: {
        open() {
            this.o = true;
            setTimeout(() => this.$refs.content.classList.remove('translate-x-full'));
        },
        close(force = false) {
            if (force || (this.can_close && !this.l)) {
                this.$refs.content.classList.add('translate-x-full');
                this.$emit('close');
                setTimeout(() => this.o = false, 50)
            }
        },
        set_loading(status) {
            this.l = status;
        }
    },
    data: () => ({o: false, l: false})
}
</script>
