<template>
  <slideover ref="modal" title="Dettagli spedizione" @close="visible = false">
    <template v-slot:content>
      <h2>Corriere: <span class="text-primary-light">{{ tracking.carrier }}</span></h2>
      <h2>{{ tracking.tracking_number_name || 'Codice tracking' }}:
        <a v-if="tracking.url" class="text-primary-light" :href="tracking.url" target="_blank"
           rel="nofollow">{{ tracking.tracking_number }}</a>
        <span v-else>{{ tracking.tracking_number }}</span>
      </h2>
      <ul class="mt-2">
        <li v-for="s in tracking_reversed" class="border-l-thin border-gray-light pb-2 pl-2">
          <p class="font-bold text-primary-light capitalize">{{ s.checkpoint_date }}</p>
          <p class="text-sm capitalize">{{ s.tracking_detail }}, {{ s.location }}</p>
        </li>
      </ul>
    </template>
  </slideover>
</template>

<script>
export default {
  name: "tracking-details",
  data: () => ({
    tracking: null,
    visible: false
  }),
  methods:{
    openModal($tracking){
      this.tracking = $tracking;
      this.visible = true;
    },
    close(){
      document.querySelector('body').style.overflowY = null;
    }
  },
  mounted() {
    this.$emitter.on('open_tracking_modal', this.openModal);
  },
  computed:{
    tracking_reversed: function(){
      return this.tracking ? this.tracking.details.reverse(): [];
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(new_value) {
        this.$nextTick(() => {
          new_value ? this.$refs.modal.open() : (this.$refs.modal ? () => {
            this.$refs.modal.close();
          } : null)
        });
        if (!this.visible) this.close();
      }
    }
  }
}
</script>

<style scoped>

</style>
