<template>
    <div class="rounded lg:container px-1.5">
        <div class="flex fixed inset-x-0 top-0 z-10 bg-white text-gray-dark text-xs p-2 space-x-3">
            <p class="text-md text-gray-dark cursor-pointer" @click="$router.go(-1)"><i class="fa-solid fa-arrow-left"></i></p>
            <h1 class="text-md text-gray-dark capitalize">Nuovo Ordine</h1>
            <!--            <button class="btn btn-primary" @click="create_ticket">Assistenza <i class="fa-solid fa-ticket ml-1.5"></i></button>-->
        </div>
        <div class="flex flex-col items-center mt-md mb-2">
            <h2 class="text-base text-gray-dark">Seleziona un cliente per inizializzare un nuovo ordine</h2>
            <div class="flex flex-grow w-full bg-white items-center justify-center space-x-1 rounded px-1">
                <input class="border-0 bg-white" type="text" placeholder="Cerca cliente" v-model="search">
                <i class="fa-solid rounded fa-magnifying-glass cursor-pointer"></i>
            </div>
        </div>
        <div class="bg-white border border-thin border-gray-light rounded-xl relative overflow-x-auto">
            <div v-if="loading"
                 class="absolute left-0 top-0 right-0 bottom-0 bg-smoke rounded flex items-center justify-center">
                <span class="text-white text-md">Caricamento <i class="fas text-base fa-circle-notch animate-spin"></i></span>
            </div>
            <table class="table-auto min-w-full h-full">
                <tr class="text-gray-dark">
                    <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light rounded-tl">ID</th>
                    <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Nome</th>
                    <th class="font-normal text-left px-1.5 py-1.5 hidden bg-gray-light">Sconto</th>
                    <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Metodo pagamento</th>
                    <th class="font-normal text-center px-1.5 py-1.5 bg-gray-light rounded-tr">Azioni</th>
                </tr>
                <tr class="border-b border-b-thin border-gray-light text-sm bg-white"
                    v-for="customer in customersFiltered" :key="customer.id">
                    <td class="p-1.5">{{ customer.id }}</td>
                    <td class="p-1.5"><router-link :to="{name: 'customers.show', params:{id: customer.id}}" replace>{{ customer.company_name }}</router-link></td>
                    <td class="p-1.5 whitespace-nowrap hidden">{{ customer.discount_mode === 'DYNAMIC' ? 'FASCE' : 'FISSO' }}
                        {{ customer.discount }}%
                    </td>
                    <td class="p-1.5">{{ customer.default_payment_method }}</td>
                    <td class="p-1.5 text-center text-base">
                        <button @click="createOrder(customer.id)"
                                     class="text-primary-light hover:text-primary"><i class="fas fa-arrow-right"></i>
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "order-create",
    data: () => ({
        customers: {
            values: []
        },
        search: null,
        loading: false
    }),
    methods: {
        load() {
            this.loading = true;
            axios.get('/clienti/customers', {params: {search: this.search}})
                .then(response => {
                    this.customers['values'] = response.data;
                    this.loading = false;
                })
        },
        async createOrder(customerId){
            let l = this.$loading.show();
            try {
                let orderId = (await axios.post('/ordini/orders',  {customer_id: customerId})).data;
                l.hide();
                this.go(orderId);
            } catch(e){
                this.$toast.error(e.response.data.message);
            }
            l.hide();
        },
        go(orderId){
            this.$router.replace({name: 'orders.edit', params: {id: orderId}});
        }
    },
    mounted() {
        this.load();
    },
    computed: {
        customersFiltered: function () {
            let tmp = this.customers.values.slice(0);
            return this.search ? tmp.filter(v => {
                return (v.id + '').includes(this.search.toLowerCase()) ||
                    v.company_name.toLowerCase().includes(this.search.toLowerCase())
            }) : tmp;
        }
    }
}
</script>

<style scoped>

</style>
