import { createRouter, createWebHistory } from 'vue-router'
import orderIndex from "../domain/orders/order-index.vue"
import customerIndex from "../domain/customers/customer-index.vue";
import customerSignup from "../domain/customers/customer-signup.vue";
import orderEdit from "../domain/orders/order-edit.vue";
import orderCreate from "../domain/orders/order-create.vue";
import orderShow from "../domain/orders/order/order-show.vue";
import productIndex from "../domain/products/product-index.vue";
import product from "../domain/products/product.vue";
import dashboard from "../domain/Dashboard/dashboard.vue";
import ticketIndex from "../domain/tickets/ticket-index.vue"
import ticket from "../domain/tickets/ticket.vue"
import login from "../domain/login/login.vue";
import orders from "../domain/customers/order/orders.vue";
import test from "../components/test.vue";
import agentsIndex from "../domain/menu/agents-index.vue";
import menu from "../domain/menu/menu.vue";
import customer from "../domain/customers/customer.vue";
import metadataIndex from "../domain/Metadata/metadata-index.vue";

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: login,
    beforeEnter: (to,from) => {
      if(isAuthenticated()) return {name: from.name}
    }
  },
  {
    path: '/',
    name: 'dashboard',
    component: dashboard
  },
  {
    path: '/customers',
    name: 'customers.index',
    component: customerIndex
  },
  {
    path: '/customers/create',
    name: 'customers.create',
    component: customerSignup
  },
  {
    path: '/customers/:id/show',
    name: 'customers.show',
    component: customer,
    props: true
  },
  {
    path:'/customers/:id/orders',
    name:'customers.orders',
    component: orders,
    props: true
  },
  {
    path: '/orders',
    name: 'orders.index',
    component: orderIndex,
  },
  {
    path: '/orders/create',
    name: 'orders.create',
    component: orderCreate
  },
  {
    path: '/orders/:id/edit',
    name: 'orders.edit',
    component: orderEdit,
    props: route => Object.assign({}, route.query, route.params),
  },
  {
    path: '/orders/:id/show',
    name: 'orders.show',
    component: orderShow,
    props: route => Object.assign({}, route.query, route.params),
  },
  {
    path: '/products/:category_name?/:subcategory_name?',
    name: 'products.index',
    component: productIndex,
    props: route => Object.assign({}, route.query, route.params),
  },
  {
    path: '/products/:id/show/:order_id?',
    name: 'products.show',
    component: product,
    props: true
  },
  {
    path: '/tickets',
    name: 'tickets.index',
    component: ticketIndex,
  },
  {
    path: '/tickets/:id/show',
    name: 'tickets.show',
    component: ticket,
    props: true
  },
  {
    path: '/menu',
    name: 'menu',
    component: menu,
  },
  {
    path:'/wp-admin/kek/test',
    name: 'kek.test',
    component: test,
  },
  {
    path: '/agents',
    name: 'agents.index',
    component: agentsIndex
  },
  {
    path:'/agents/:id/show',
    name:'agents.show',
    component: dashboard,
    props: true
  },
  {
    path: '/metadata',
    name: 'metadata.index',
    component: metadataIndex
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

let isAuthenticated = () => !!localStorage['key-api'];

router.beforeEach(async (to, from) => {
  if(!isAuthenticated() && to.name !== 'Login'){
    return {name: 'Login'}
  }
});

export default router
