<template>
    <div class="lg:container p-1.5 flex flex-col flex-grow space-y-2 lg:h-screen">
        <div class="flex justify-between fixed bg-white inset-x-0 top-0 z-10 text-gray-dark text-xs p-2 space-x-3">
            <div class="flex space-x-3">
                <button v-if="step === 3" class="text-md text-gray-dark cursor-pointer" @click="step = 1"><i
                        class="fa-solid fa-arrow-left"></i></button>
                <button @click="$router.go(-1)" v-else class="text-md text-gray-dark cursor-pointer"
                             ><i
                        class="fa-solid fa-arrow-left"></i></button>
                <h1 class="text-md text-gray-dark capitalize">Ordine {{ id }}</h1>
            </div>
            <button class="self-end text-md" @click="create_ticket"><i class="fa-solid fa-headset"></i></button>
        </div>
        <div class="h-4"></div>
        <div class="w-full flex flex-col flex-grow h-full" v-if="loaded_all">
            <order-products class="h-full" v-if="step === 1" :changeStep="changeStep" :load="load"
                            :order_id="order.id" :products="products" :discounts="discounts"
                            :order_discount="order.discount_percentage" :customer_id="order.customer_id"
                            :pricing="order.pricing" :from="from"></order-products>
            <order-summary v-else :changeStep="changeStep" :order="order" :update="update" :load="load"
                           :payments="payments" :branches="branches"
                           :invoicing_branches="invoicing_branches"></order-summary>
        </div>
    </div>
</template>

<script>
import StepComponent from "./component/step-component.vue";
import OrderGeneral from "./order-general.vue";
import OrderProducts from "./order-products.vue";
import OrderSummary from "./order-summary.vue";

export default {
    name: "order-edit",
    components: {OrderSummary, OrderProducts, OrderGeneral, StepComponent},
    props: {
        id: null,
        from: null
    },
    data: () => ({
        step: 1,
        order: null,
        products: null,
        payments: null,
        branches: null,
        discounts: [50, 55, 60, 62],
        shipments: null,
        shipping_policies: null,
        invoicing_branches: null,
        loaded_all: false
    }),
    methods: {
        async load(show = true) {
            let l = null;
            if(show)
               l = this.$loading.show();
            try {
                let responses = (await Promise.all([axios.get('/ordini/orders/' + this.$props.id),
                    axios.get('/ordini/orders/' + this.$props.id + '/products')]));

                this.branches = responses[0].data.branches;
                this.invoicing_branches = responses[0].data.invoicing_branches;
                this.payments = responses[0].data.payment_methods;
                let discount = responses[0].data.discount;
                this.order = responses[0].data.order;
                this.shipments = responses[0].data.shipments;
                this.shipping_policies = responses[0].data.shipping_policies;
                this.products = responses[1].data;
                this.loaded_all = true;

                if (this.order.pricing.natural_person) this.discounts = [];
                if (!this.discounts.filter((v) => v === discount).length) {
                    this.discounts.push(discount);
                    this.discounts.sort();
                }
            } catch (e) {
                this.$toast.error(e.response.data.message || e);
                if(show) l.hide();
                await this.$router.push({name: 'orders.index'});
            }
            if(show) l.hide();
        },
        async update(show = true) {
            let l = null;
            if(show) l = this.$loading.show();
            let order = JSON.parse(JSON.stringify(this.order));
            try {
                let response = (await axios.put('/ordini/orders/' + this.order.id, order));
                await this.load();
                if(show) l.hide();
                return response.data;
            } catch (e) {
                this.$toast.error(e.response.data.message);
                if(show) l.hide();
            }
        },
        create_ticket() {
            this.$emitter.emit('open_ticket', {
                flag: 1,
                ticket_class: 'App\\Domain\\Checkout\\Models\\Order',
                id: this.$props.id
            });
        },
        changeStep(step) {
            this.step = step;
        }
    },
    mounted() {
        this.load();
        document.querySelector('#main').classList.add('h-full');
    },
    destroyed() {
        document.querySelector('#main').classList.remove('h-full');
    }
}
</script>

<style scoped>

</style>
