<template>

  <div class="flex flex-col">
    <span class="text-gray-dark text-xs">Utente: {{ user.name }}</span>
    <div class="flex flex-col space-y-2 mt-4">
      <span class="text-gray-dark text-md">Prodotti</span>
      <div class="flex w-full mt-4 space-x-1 sm:space-x-2 p-1">
        <ic class="flex flex-col flex-grow">
          <label class="text-gray-dark text-xs" for="sku">SKU</label>
          <input type="text" v-model="product_search.sku" id="sku">
          <span v-if="product_searched && Object.keys(product_searched).length !== 0" class="text-xs mt-1"
                :class="product_searched['availability']['color']">{{
              product_searched.availability.text
            }}</span>
          <span v-else-if="product_searched" class="text-xs text-secondary mt-1">Prodotto non trovato</span>
        </ic>
        <ic class="flex flex-col flex-grow">
          <label class="text-gray-dark text-xs" for="quantity">Quantità</label>
          <div class="flex flex-col space-y-1">
            <input type="text" v-model="product_search.quantity" id="quantity" @focus="$event.target.select()">
            <span v-if="product_searched && Object.keys(product_searched).length !== 0"
                  class="text-xs text-primary mt-1 self-end">{{ product_searched.line_price }}</span>
          </div>
        </ic>
        <div class="flex flex-col space-y-1">
          <span class="text-gray-dark text-xs">&#8205;</span>
          <button @click="update_item(product_searched.id, product_searched.quantity, false)"
                  :class="{disabled: loading || !product_searched || (Object.keys(product_searched).length === 0) || (Object.keys(product_searched).length !== 0 && product_searched.availability.type > 3)}"
                  class="btn btn-primary cursor-pointer self-end">+
          </button>
        </div>
      </div>
      <cart-line v-if="items" v-for="(item) in items" :key="item.id" :item="item"
                 :change_quantity="change_quantity"></cart-line>
    </div>
  </div>


</template>

<script>
import CartLine from "./cart-line.vue";
import _ from "lodash"

export default {
  name: "cart",
  components: {CartLine},
  props: {
    user: {
      customer_id: null,
      id: null,
      name: null,
    },
    cart: null,
    slide: null,
  },
  data: () => ({
    product_search: {
      sku: null,
      quantity: null
    },
    product_searched: null,
    info: {
      total: null,
      pricing: null
    },
    items: null,
    loading: false
  }),
  methods: {
    async change_quantity(item, quantity) {
      if (item.quantity !== quantity) {
        item.quantity = quantity;
        await this.update_item(item.id, quantity);
      }
    },
    async update_item(id, quantity, overwrite = true) {
      this.$props.slide.set_loading(true);
      let response_data = (await axios.put('/clienti/users/' + this.$props.user.id + '/carts/' + this.$props.cart, {
        product: id,
        quantity: quantity,
        overwrite: overwrite
      })).data.data;

      this.items = response_data.items;
      this.$emitter.emit('change_can_create_order', this.items.length > 0);
      this.info['total'] = response_data.total;
      this.info['pricing'] = response_data.pricing;

      this.product_searched = null;
      this.product_search = {
        sku: null,
        quantity: null
      };
      this.$emitter.emit('change_cart_total', this.info.total);
      this.$props.slide.set_loading(false);
    },
    search_product() {
      axios.get('/customer/' + this.$props.user.customer_id + '/product/' + this.product_search.sku + '/show', {
        params: {
          quantity: this.product_search.quantity
        }
      })
          .then(response => {
            this.product_searched = response.data
            this.product_search.quantity = this.product_searched.quantity;
          }).finally(() => this.loading = false)
    },
    debounce: _.debounce(function () {
      this.search_product();
    }, 400),
  },
  async mounted() {
    this.$props.slide.set_loading(true);
    let response_data = (await axios.get('/clienti/users/' + this.$props.user.id + '/carts/' + this.$props.cart)).data.data;
    this.items = response_data.items;
    this.$emitter.emit('change_can_create_order', this.items.length > 0);
    this.info['total'] = response_data.total;
    this.info['pricing'] = response_data.pricing;
    this.$emitter.emit('change_cart_total', this.info.total);
    this.$props.slide.set_loading(false);
  },
  watch: {
    cart: async function () {
      this.$props.slide.set_loading(true);
      this.items = (await axios.get('/clienti/users/' + this.$props.user.id + '/carts/' + this.$props.cart)).data.data;
      this.$emitter.emit('change_can_create_order', this.items.length > 0);
      this.this.$props.slide.set_loading(false);
    },
    'product_search.sku': function () {
      this.product_search.quantity = null;
    },
    'product_search.quantity': function () {
      if (this.product_searched && this.product_searched.quantity === this.product_search.quantity)
        return;
      this.product_searched = null;
      if (this.product_search.quantity && this.product_search.sku) {
        this.loading = true
        this.debounce();
      }
    }
  }
}
</script>

<style scoped>

</style>
