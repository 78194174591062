<template>
    <div class="relative">
        <button :class="[btn_c, base_c, loading || disabled ? loading_c : null]" @click="handler" :disabled="loading || disabled">
            <i class="fa-solid fa-spinner animate-spin" v-if="loading"></i>
            <span><slot></slot></span>
        </button>
        <div v-if="loading || disabled" class="absolute left-0 right-0 top-0 bottom-0"></div>
    </div>
</template>

<script>
export default {
    name: "btn",
    props: {
        btn_c: {
            type: String,
            default: 'btn-primary'
        },
        base_c: {
            type: String,
            default: 'btn flex space-x-1.5 items-center transition'
        },
        loading_c: {
            type: String,
            default: 'filter saturate-0 opacity-40'
        },
        callback: {
            type: Function,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        confirm: {
            type: String | null,
            default: null
        }
    },
    methods: {
        async handler() {
            this.loading = true;
            try {
                if (this.confirm) await this.$dialog.confirm(this.confirm);
                this.callback()
            } catch ({}) {}
            this.loading = false;
        }
    },
    data: () => ({
        loading: false
    })
}
</script>