<template>
  <div class="h-screen">
    <div id="main">
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <create_ticket></create_ticket>
    <modal ref="update" :close_on_backdrop="false">
      <div class="flex flex-col space-y-1.75 p-1">
        <span class="text-md font-bold text-primary-light">Aggiornamento disponibile</span>
        <span class="text-gray-dark">Premendo <span class="text-primary-light">Aggiorna</span> {{ mode }} verrà riavviata.</span>
        <div class="flex w-full justify-end">
          <div class="flex space-x-2">
            <button class="btn btn-outline-primary" @click="$refs.update.close()">Annulla</button>
            <button class="btn btn-primary" @click="install()">Aggiorna</button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {registerSW} from 'virtual:pwa-register'

export default {
  name: "navigation",
  data: () => ({
    updateSW: null
  }),
  beforeMount() {
    if ("serviceWorker" in navigator) {
      let vm = this;
      this.updateSW = registerSW({
        onRegisteredSW(swUrl, r) {
          if (r?.installing) {
            r.installing.addEventListener('statechange', (e) => {
              if (r?.active?.state === 'activated')
                this.registerPeriodicSync(swUrl, r)
            })
          } else if (r?.active?.state === 'activated') {
            this.registerPeriodicSync(swUrl, r)
          }

        },
        onNeedRefresh() {
          if (vm.$refs.update.isClosed()) vm.$refs.update.open();
        }
      });
    }
  },
  methods: {
    install() {
      this.updateSW(true);
    },
    registerPeriodicSync(swURL, r) {
      setInterval(async () => {
        if (('connection' in navigator) && !navigator.onLine)
          return

        const resp = await fetch(swUrl, {
          cache: 'no-store',
          headers: {
            'cache': 'no-store',
            'cache-control': 'no-cache',
          },
        })

        if (resp?.status === 200)
          await r.update()
      }, 60 * 60 * 1000)
    }


  },
  computed: {
    mode: function () {
      return window.matchMedia('(display-mode: standalone)').matches ? "l'app" : "la pagina";
    }
  }
}
</script>

<style scoped>

</style>
