<template>
  <slideover ref="cart_slideover"
             :title="(cart.cart_type && cart.cart_type === 1 ? 'Lista Prenotazioni' : 'Carrello Acquisti')">
    <template v-slot:content>
      <cart :cart="cart.id" :user="user" :slide="get_cart()"></cart>
    </template>
    <template v-slot:actions>
      <div class="flex items-center justify-between w-full">
        <span class="text-gray-dark text-md">Totale: {{ this.cart_total }}</span>
        <button v-if="cart.cart_type === 0" class="btn btn-primary" :class="{disabled: !can_create_order}" @click="create_order">Crea Ordine</button>
      </div>

    </template>
  </slideover>
</template>

<script>
import Cart from "../domain/customers/cart.vue";

export default {
  name: "cart_slide",
  components: {Cart},
  props:{
    user: {},
    cart: null,
  },
  methods:{
    async create_order(){
      this.$refs.cart_slideover.set_loading(true)
      try{
        let id = (await axios.post('cart/'+this.cart.id+ '/create-order')).data;
        this.$refs.cart_slideover.set_loading(false);
        this.$refs.cart_slideover.close(true);
        document.querySelector('body').style.overflowY = null;
        this.$router.push({name: 'orders.edit', params: {id: id}});
      }catch(e){
        this.$toast.error(e.response.data.message || "Errore");
        this.$refs.cart_slideover.set_loading(false);
      }
    },
    get_cart() {
      return this.$refs.cart_slideover;
    },
    open(){
      this.$refs.cart_slideover.open();
    }
  },
  data: () => ({
    loading: true,
    cart_total: '',
    can_create_order: false,
  }),
  mounted(){
    this.$emitter.on('change_cart_total', (e) => this.cart_total = e);
    this.$emitter.on('change_can_create_order', (e) => this.can_create_order = e);
    //this.$emitter.on('cart_slide_open', () => this.$refs.cart_slideover.open());
  }
}
</script>

<style scoped>

</style>
