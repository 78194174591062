<template>
  <div>
    <div class="h-sm"></div>
    <div id="mobile-navigation" class="block fixed inset-x-0 bottom-0 z-30 bg-white text-gray-dark text-xs">
      <div class="flex justify-between">
        <router-link :to="{name: 'dashboard'}"
                     class="text-center justify-center items-center w-full pt-2 pb-2 inline-block space-y-1">
          <div><i class="fa-solid fa-chart-line text-md 2xl:text-lg"></i></div>
          <div class="hidden"><span>Dashboard</span></div>
        </router-link>
        <router-link :to="{name: 'customers.index'}"
                     class="text-center justify-center items-center w-full pt-2 pb-2 inline-block space-y-1">
          <div><i class="fa-solid fa-users text-md 2xl:text-lg"></i></div>
          <div class="hidden"><span>Clienti</span></div>
        </router-link>
        <router-link :to="{name: 'orders.index'}"
                     class="text-center justify-center items-center w-full pt-2 pb-[1.2rem] inline-block space-y-1">
          <div><i class="fa-solid fa-cart-shopping text-md 2xl:text-lg"></i></div>
          <div class="hidden"><span>Ordini</span></div>
        </router-link>
        <router-link :to="{name: 'products.index'}"
                     class="text-center justify-center items-center w-full pt-2 pb-2 inline-block space-y-1">
          <div><i class="fa-solid fa-shop text-md 2xl:text-lg"></i></div>
          <div class="hidden"><span>Prodotti</span></div>
        </router-link>

        <router-link :to="{name: 'menu'}"
                     class="text-center justify-center items-center w-full pt-2 pb-2 inline-block space-y-1">
          <div><i class="fa-solid fa-bars text-md 2xl:text-lg"></i></div>
          <div class="hidden"><span>Menu</span></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navbar"
}
</script>

<style scoped>

</style>
