<template>
    <div class="container rounded bg-white p-2 relative mb-2 flex flex-col space-y-3">
        <div class="flex flex-col lg:flex-row lg:space-x-2 space-y-3 lg:space-y-0">
            <div class="flex flex-col w-full">
                <label class="text-gray-dark text-xs" for="branch">Indirizzo di spedizione</label>
                <div class="flex space-x-2">
                    <v-select class="flex-grow" :options="branch_list" v-model="order.branch_id"
                              :clearable="false" :filter="filter_branch" :reduce="b => b.id" label="description">
                        <template #option="{recipient, description, dropshipping, id}">
                            <div class="flex items-center">
                                <i class="fas fa-truck text-primary text-xs mr-1" v-if="dropshipping"></i>
                                <p class="text-sm font-bold ">{{ recipient }}</p>
                            </div>
                            <p class="text-xs pb-1">{{ description }}</p>
                        </template>
                        <template #selected-option="{recipient, description, dropshipping}">
                            <div class="flex flex-col">
                                <p class="text-xs font-bold">{{ recipient }}</p>
                                <p class="">{{ description }}</p>
                            </div>
                        </template>
                    </v-select>
                    <button @click="openBranchSlideover()"><i class="fa-solid fa-plus"></i></button>
                </div>
            </div>
        </div>
        <div class="flex flex-col lg:flex-row lg:space-x-2 space-y-2 lg:space-y-0">
            <div class="flex flex-col w-full">
                <label class="text-gray-dark text-xs" for="invoicing_branch">Indirizzo di Fatturazione</label>
                <select v-model="order.invoicing_branch_id" @change="update" id="invoicing_branch"
                        class="py-1 border-solid border-gray-light bg-transparent">
                    <option :value="index" v-for="(branch, index) in invoicing_branches">{{
                        branch.description
                        }}
                    </option>
                </select>
            </div>
        </div>
        <div class="flex flex-col">
            <label class="text-gray-dark text-xs" for="payment_method">Metodo di pagamento</label>
            <select v-model="order.payment_method_id" @change="update" id="payment_method"
                    class="py-1 border-solid border-gray-light bg-transparent">
                <option :value="index" v-for="(payment, index) in payments">{{
                    payment
                    }}
                </option>
            </select>
        </div>
        <slideover ref="slideover" :title="branch.id ? 'Modifica indirizzo' : 'Nuovo indirizzo'"
                   :actions="slideover_actions">
            <template v-slot:content>
                <div class="grid grid-cols-3 gap-1.5">
                    <ic c="col-span-3" :e="branch_errors.recipient">
                        <label>Destinatario</label>
                        <input type="text" v-model="branch.recipient">
                    </ic>
                    <ic :e="branch_errors.country_code">
                        <label>Nazione</label>
                        <select v-model="branch.country_code">
                            <option :value="id" v-for="({id, label}) in country_codes">{{ label }}</option>
                        </select>
                    </ic>
                    <ic :e="branch_errors.address" c="col-span-2">
                        <label>Indirizzo</label>
                        <input type="text" v-model="branch.address">
                    </ic>
                    <ic :e="branch_errors.city">
                        <label>Città</label>
                        <input type="text" v-model="branch.city">
                    </ic>
                    <ic :e="branch_errors.zip_code">
                        <label>CAP</label>
                        <input type="text" v-model="branch.zip_code">
                    </ic>
                    <ic :e="branch_errors.province">
                        <label>Provincia</label>
                        <input type="text" v-model="branch.province">
                    </ic>
                    <ic :e="branch_errors.email" c="col-span-3">
                        <label>Email</label>
                        <input type="email" v-model="branch.email">
                    </ic>
                    <ic :e="branch_errors.phone" c="col-span-3">
                        <label>Telefono</label>
                        <input type="tel" v-model="branch.phone">
                    </ic>
                    <ic :e="branch_errors.notes" c="col-span-3">
                        <label>Note</label>
                        <textarea v-model="branch.notes"></textarea>
                    </ic>
                </div>
            </template>
        </slideover>
    </div>

</template>

<script>
export default {
    name: "order-general",
    props: {
        payments: {},
        invoicing_branches: {},
        branches: {},
        order: {},
        changeStep: null,
        update: null,
        load: null
    },
    data: () => ({
        branch: {branch_type: 2, country_code: 'IT'},
        branch_errors: {},
        country_codes: [],
        branch_types: []
    }),
    methods: {
        filter_branch(options, search) {
            search = search.toLowerCase();
            return options.filter(o => (o.recipient + ' ' + o.description).toLowerCase().includes(search));
        },
        async openBranchSlideover() {
            this.$refs.slideover.open();
            this.$refs.slideover.set_loading(true);
            try {
                if (!this.country_codes || !this.country_codes.length) {
                    this.country_codes = (await axios.get('/clienti/country_codes')).data;
                }
                this.$refs.slideover.set_loading(false);
            } catch (e) {
                this.$toast.error("Qualcosa è andato storto");
                this.$refs.slideover.set_loading(false);
                this.$refs.slideover.close();
            }
        },
        async storeBranch() {
            this.$refs.slideover.set_loading(true);
            try {
                let id = (await axios.post('/clienti/customers/' + this.$props.order.customer_id + '/branches', this.branch)).data.id;
                this.$props.order.branch_id = id;
                this.$toast.success('Indirizzo creato correttamente');
                this.branch = {branch_type: 2, country_code: 'IT'};
                this.$refs.slideover.set_loading(false);
                this.$refs.slideover.close();
            } catch (e) {
                if (e.response) {
                    if (e.response.data)
                        if (e.response.data.errors)
                            this.branch_errors = e.response.data.errors;
                        else if (e.response.data.message)
                            this.$toast.error(e.response.data.message);
                } else {
                    this.$toast.error("Il cliente ha già una sede legale")
                }
                this.$refs.slideover.set_loading(false);
            }
        },
        async changedBranch() {
            let l = this.$loading.show();
            await this.$props.update(false);
            await axios.post('/ordini/orders/' + this.$props.order.id + '/validate')
            await this.$props.load(false);
            l.hide();
        }
    },
    computed: {
        branch_list() {
            let branches = this.$props.branches;
            return branches.concat([{
                id: null,
                recipient: 'Ritiro in sede LED Italia',
                description: 'Via Salvo d\'Acquisto 19, Pozzuolo Martesana 20060'
            }]);
        },
        slideover_actions: ctx => [{name: 'Crea indirizzo', callback: ctx.storeBranch, class: 'btn-primary'}]
    },
    async mounted() {
        this.$watch('order.branch_id', this.changedBranch);
    }
}
</script>

<style scoped>

</style>
