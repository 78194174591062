<template>
  <div class="">
    <div class="bg-white w-full px-1.5" id="bar">
      <p class="sm:container text-gray-dark text-md pt-2">Assistenza</p>
    </div>
    <div class="bg-white z-10 text-gray-dark text-xs md:text-sm p-1.5 space-x-3 w-full" id="header">
      <div class="flex flex-col space-y-1.5 w-full sm:container">
        <div class="flex w-full items-center justify-center space-x-2">
          <div class="flex flex-grow bg-gray-light items-center pr-1 space-x-1 rounded">
            <input class="border-0 bg-gray-light" placeholder="Cerca ticket" type="search" v-model="search">
            <i class="fa-solid rounded pr-1 fa-magnifying-glass cursor-pointer"></i>
          </div>
          <p class="text-base text-gray-dark cursor-pointer mr-2 hover:text-primary" @click="$refs.filter.open()"><i
              class="fa-solid fa-filter"></i></p>
        </div>
        <Transition
            enter-active-class="duration-300 ease-in-out"
            enter-from-class="transform-all opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="transform-all opacity-0"
        >
          <div v-if="filters.start_date || filters.end_date || filters.subject_type !== null || filters.status !== null || filters.type !== null || search"
               class="flex flex-wrap space-x-2">
            <TransitionGroup
                enter-active-class="duration-300 ease-in-out"
                enter-from-class="transform-all opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="transform-all opacity-0"
            >
                    <span v-if="filters.start_date" @click="filters.start_date = null"
                          class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                        class="fa-solid fa-xmark"></i> Dal {{
                        $luxon(filters.start_date)
                      }} </span>
              <span v-if="filters.end_date" @click="filters.end_date = null"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> Al {{
                  $luxon(filters.end_date)
                }} </span>
              <span v-if="filters.subject_type !== null" @click="filters.subject_type = null"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> Oggetto: {{
                  subject_types.filter(v => v.value === filters.subject_type)[0].label
                }} </span>
              <span v-if="filters.status !== null" @click="filters.status = null"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> Stato: {{
                  status_filters.filter(v => v.value === filters.status)[0].label
                }} </span>
              <span v-if="filters.type !== null" @click="filters.type = null"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> Tipo: {{
                  types.filter(v => v.value === filters.type)[0].label
                }} </span>
              <span v-if="search" @click="search = null"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                  class="fa-solid fa-xmark"></i> {{search}} </span>
            </TransitionGroup>
          </div>
        </Transition>
      </div>
    </div>

    <div class="flex space-x-1.5">
      <button @click="open_ticket()"
              class="btn btn-primary text-xs flex items-center justify-center h-[3.5rem] w-[3.5rem] z-10
                         rounded-full fixed bottom-[5rem] right-2 md:bottom-md md:right-3 lg:right-[3.5rem] shadow">
        <i class="fas fa-plus"></i></button>
    </div>

    <div class="lg:container mt-2 p-1.5">
      <div class="bg-white border border-thin border-gray-light rounded-xl relative overflow-x-auto">
        <div v-if="loading"
             class="absolute left-0 top-0 right-0 bottom-0 bg-smoke rounded flex items-center justify-center">
                    <span class="text-white text-md">Caricamento <i
                        class="fas text-base fa-circle-notch animate-spin"></i></span>
        </div>
        <table class="table-auto min-w-full h-full">
          <tr class="text-gray-dark">
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">ID</th>
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light rounded-tl">Titolo</th>
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Tipo</th>
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Stato</th>
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Messaggi</th>
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Creazione</th>
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Ultimo aggiornamento</th>
            <th class="font-normal text-center px-1.5 py-1.5 bg-gray-light rounded-tr">Azioni</th>
          </tr>
          <TransitionGroup tag="tbody"
                           enter-active-class="duration-700 ease-in-out"
                           enter-from-class="transform-all opacity-0"
                           enter-to-class="opacity-100"
                           leave-active-class="duration-100 ease-in"
                           leave-from-class="opacity-100"
                           leave-to-class="transform-all opacity-0"
          >
          <tr class="border-b border-b-thin border-gray-light text-sm bg-white"
              v-for="ticket in ticketsFiltered" :key="ticket.id">
            <td class="p-1.5">{{ ticket.id }}</td>
            <td class="p-1.5">
              <div class="flex flex-col">
                <span class="text-xs font-bold text-gray-dark">{{ ticket.subject || '' }}</span>
                <span>{{ ticket.title }}</span>
              </div>
            </td>
            <td class="p-1.5">{{ ticket.type_description }}</td>
            <td class="p-1.5">{{ ticket.status_description }}</td>
            <td class="p-1.5 text-center">{{ ticket.messages_count }}</td>
            <td class="p-1.5">{{ $luxon(ticket.created_at) }}</td>
            <td class="p-1.5">{{ $luxon(ticket.updated_at) }}</td>
            <td class="p-1.5 text-center text-base">
              <router-link :to="{name: 'tickets.show', params: {id: ticket.id}}"
                           class="text-primary-light hover:text-primary">
                <i class="fas fa-arrow-right"></i>
              </router-link>
            </td>
          </tr>
          </TransitionGroup>
        </table>
      </div>
    </div>
    <slideover ref="filter" title="Filtri">
      <template v-slot:content>
        <ic>
          <label for="start_date">Inizio</label>
          <input type="date" id="start_date" v-model="filters.start_date" :max="filters.end_date">
        </ic>
        <ic>
          <label for="end_date">Fine</label>
          <input type="date" id="end_date" v-model="filters.end_date" :min="filters.start_date">
        </ic>
        <ic>
          <label for="status">Stato</label>
          <select v-model="filters.status" id="status">
            <option v-for="status in status_filters" :value="status.value">{{ status.label }}</option>
          </select>
        </ic>
        <ic>
          <label for="subject_type">Tipologia Oggetto</label>
          <select v-model="filters.subject_type" id="subject_status">
            <option v-for="subject_type in subject_types" :value="subject_type.value">{{ subject_type.label }}</option>
          </select>
        </ic>
        <ic>
          <label for="type">Tipo</label>
          <select v-model="filters.type" id="type">
            <option v-for="type in types" :value="type.value">{{ type.label }}</option>
          </select>
        </ic>
      </template>
    </slideover>
    <div class="h-[7rem] w-full xl:hidden"></div>
    <navbar></navbar>
  </div>
</template>

<script>
import navbar from "../../components/navbar.vue";

export default {
  name: "ticket-index.vue",
  components: {navbar},
  data: () => ({
    search: null,
    tickets: {
      values: []
    },
    loading: true,
    filters:{
      start_date: null,
      end_date: null,
      status: null,
      subject_type: null,
      type: null
    },
    status_filters: [
      {label: 'Tutti', value: null},
      {label: 'Aperto', value: 0},
      {label: 'Chiuso', value: 1}
    ],
    subject_types: [
      {label: 'Tutti', value: null},
      {label: 'Prodotto', value: 'Product'},
      {label: 'Ordini', value: 'Order'},
      {label: 'Clienti', value: 'Customer'},
      {label: 'Senza Oggetto', value: -1}
    ],
    types: [
      {label: 'Tutti', value: null},
      {label: 'Commerciale', value: 1},
      {label: 'Logistica', value: 2},
      {label: 'Amministrazione', value: 3},
      {label: 'IT e Dati', value: 4}
    ]
  }),
  methods: {
    async load() {
      this.loading = true;
      this.tickets.values = (await axios.get('/assistenza/tickets')).data;
      this.loading = false;
    },
    open_ticket() {
      this.$emitter.emit('open_ticket', {});
    }
  },
  async mounted() {
    let header = document.getElementById('header');
    let sticky = header.offsetTop;
    let bar = document.getElementById('bar');
    window.onscroll = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add('sticky');
        header.classList.add('top-0');
        bar.classList.remove('top-0');
      } else {
        bar.classList.add('top-0');
        header.classList.remove('sticky');
        header.classList.remove('top-0');
      }
    }
    let a = (new Date());
    this.filters.status = 0

    await this.load();
  },
  computed: {
    ticketsFiltered: function () {
      let tmp = this.tickets.values.slice(0);
      let tmp2 = this.search ? tmp.filter(v => v.title.toLowerCase().includes(this.search.toLowerCase()) ||
          v.id == this.search.toLowerCase() ||
          (v.subject && v.subject.toLowerCase().includes(this.search.toLowerCase())) ||
          v.type_description.toLowerCase().includes(this.search.toLowerCase()) ||
          v.status_description.toLowerCase().includes(this.search.toLowerCase())) : tmp;
      return tmp2
          .filter(v => this.filters.subject_type ?
              (this.filters.subject_type === -1 ? !v.subject_type : (v.subject_type && v.subject_type.includes(this.filters.subject_type))) : true)
          .filter(v => this.filters.status !== null ? this.filters.status === v.status : true)
          .filter(v => this.filters.type !== null ? this.filters.type === v.type: true)
          .filter(v => this.filters.start_date ? new Date(this.filters.start_date) <= new Date(v.updated_at).setHours(0,0,0,0) : true)
          .filter(v => this.filters.end_date ? new Date(this.filters.end_date) >= new Date(v.updated_at).setHours(0,0,0,0) : true)
    }
  }
}
</script>

<style scoped>

</style>
