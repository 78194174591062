<template>
  <div class="flex-grow h-full flex flex-col">

    <div class="container rounded bg-white p-2 relative flex-grow h-full flex flex-col space-y-3">
      <router-link v-if="order_id" :to="{name:'products.index', query: {'order_id': order_id, 'from': from}}"
                   class="text-gray-dark underline hover:text-secondary self-end text-base">Visualizza catalogo
      </router-link>
      <div class="flex flex-col flex-grow">
        <div class="lg:grid lg:grid-cols-3 lg:gap-4 flex space-x-1 mb-2 w-full">
          <ic class="flex flex-col flex-grow">
            <label class="text-gray-dark text-xs" for="sku">SKU
              <router-link v-if="product_searched && Object.keys(product_searched).length !== 0"
                           :to="{name: 'products.show', params: {id: product_searched.id, order_id: order_id}}"
                           class="text-gray"><i class="fa-solid fa-circle-info"></i></router-link>
            </label>
            <input type="text" v-model="product_search.sku" id="sku" @focus="$event.target.select()">
            <span v-if="product_searched && Object.keys(product_searched).length !== 0" class="text-xs mt-1"
                  :class="product_searched['availability']['color']">{{
                product_searched.availability.text
              }} <span class="text-[0.7rem] text-gray-dark text-center" v-if="product_searched.raw_quantity">
                                            (
                                            <span
                                                v-if="product_searched.raw_quantity[1]">IT: {{
                                                Math.max(0, product_searched.raw_quantity[1].quantity)
                                              }} {{ product_searched.base_uom || 'pz' }}, </span>
                                            <span
                                                v-if="product_searched.raw_quantity[2]">EU: {{
                                                Math.max(0, product_searched.raw_quantity[2].quantity)
                                              }} {{ product_searched.base_uom || 'pz' }}</span>)
                                        </span></span>
            <span v-else-if="product_searched" class="text-xs text-secondary mt-1">Prodotto non trovato</span>
          </ic>
          <ic class="flex flex-col flex-grow">
            <label class="text-gray-dark text-xs" for="quantity">Quantità</label>
            <div class="flex flex-col space-y-1">
              <input type="number" v-model="product_search.quantity" id="quantity"
                     @input="this.loading = true; this.debounce()" @focus="$event.target.select()">
              <span v-if="product_searched && Object.keys(product_searched).length !== 0"
                    class="text-xs mt-1 flex flex-col md:flex-row justify-between"><span
                  :class="product_searched.promo_price? 'text-flags-promo' : 'text-gray'">{{
                  product_searched.promo_price || product_searched.base_price
                }}/{{ product_searched.base_uom }}</span><span
                  class="text-primary">{{
                  product_searched.line_price
                }} ({{ product_searched.unit_price }}/{{ product_searched.base_uom }})</span></span>
            </div>
          </ic>
          <div class="flex space-x-1 lg:space-x-2">
            <ic class="flex flex-col flex-grow">
              <label class="text-gray-dark text-xs" for="discount">Sconto</label>
              <select v-model="product_search.discount" id="discount"
                      class="border-solid border-gray-light bg-transparent min-w-[5rem]">
                <option v-for="discount in discounts" :value="discount">{{
                    discount
                  }}%
                </option>
              </select>
            </ic>
            <div class="flex flex-col space-y-1">
              <span class="text-gray-dark text-xs">&#8205;</span>
              <button
                  @click="update_item(product_searched.id, product_searched.quantity, product_search.discount, false)"
                  :class="{disabled: loading || !product_searched || (Object.keys(product_searched).length === 0) || (Object.keys(product_searched).length !== 0 && product_searched.availability.type > 2)}"
                  class="btn btn-primary cursor-pointer self-end">+
              </button>
            </div>
          </div>
        </div>
        <hr class="mt-2 mb-4 text-gray-light text-sm">
        <div class="overflow-y-auto flex-grow h-full lg:h-4 space-y-2">
          <order-products-line v-if="products" v-for="item in products" :key="item.id" :item="item" :order_id="order_id"
                               :discounts="discounts"
                               :update_item="update_item">
          </order-products-line>
        </div>


      </div>
      <div class="block fixed inset-x-0 bottom-0 z-10 bg-white text-gray-dark p-2">
        <div class="flex space-y-0 flex-row justify-between items-center lg:container">
            <span
                class="text-md text-gray-dark justify-self-start">Totale: {{
                pricing.final_price
              }}</span>
          <div class="flex self-end space-x-2">
            <button class="btn btn-primary" :class="{disabled: !forward}" @click="changeStep(3)">Continua</button>
          </div>
        </div>
      </div>
    </div>
    <slideover ref="slideover" title="Acquisti passati">
      <template v-slot:content>
        <div class="space-y-2 p-2">
          <div class="flex flex-col space-y-1 justify-center items-center">
            <img :src="history_product.thumbnail" :alt="'sku '+history_product.sku" class="h-md md:h-xl">
            <span class="text-gray-dark font-bold underline">SKU {{ history_product.sku }}</span>
          </div>
          <div
              class="flex flex-col md:flex-row text-gray-dark w-full py-1 px-2 border-thin rounded md:items-center md:justify-between">
            <div class="flex flex-col">
              <span class="text-base underline mb-1.5">Ordine corrente</span>
              <span>Prezzo di listino: {{ history_product.base_price }}<span
                  class="text-xs">/{{ history_product.base_uom }}</span></span>
              <span class="text-flags-promo"
                    v-if="history_product.promo_price">Prezzo promo: {{ history_product.promo_price }}<span
                  class="text-xs">/{{ history_product.base_uom }}</span></span>
              <span>Sconto applicato: {{ history_product.discount }}%</span>
              <span>Quantità attuale: {{ history_product.quantity }}</span>
              <span :class="{'font-bold': min_history === parseInt(history_product.unit_price.match(/\d/g).join(''))}">Prezzo finale: {{
                  history_product.unit_price
                }}<span
                    class="text-xs">/{{ history_product.base_uom }}</span></span>
            </div>
            <span class="text-availability-0 text-sm"
                  v-if="min_history === parseInt(history_product.unit_price.match(/\d/g).join(''))">Miglior prezzo <i
                class="fa-solid fa-check text-md"></i></span>
            <span class="text-secondary text-sm flex items-center space-x-1.5" v-else><span>Non il miglior prezzo</span><i
                class="fa-solid fa-xmark text-md"></i></span>
          </div>
          <p class="my-2 text-md text-gray-dark">Ultimi ordini</p>
          <div v-for="old_product in history"
               class="flex flex-col md:flex-row text-gray-dark w-full py-1 px-2 border-thin border-gray-light rounded md:items-center md:justify-between"
               :key="history.order_id">
            <div class="flex flex-col">
              <span class="text-base underline mb-1.5">Ordine {{ old_product.order_id }}</span>
              <span>Effettuato il: {{ old_product.created_at }}</span>
              <span>Prezzo di listino: {{ old_product.base_price }}<span
                  class="text-xs">/{{ history_product.base_uom }}</span></span>
              <span class="text-flags-promo"
                    v-if="old_product.promo_price">Prezzo promo: {{ old_product.promo_price }}<span
                  class="text-xs">/{{ history_product.base_uom }}</span></span>
              <span>Sconto applicato: {{ old_product.discount_percentage }}%</span>
              <span>Acquistati: {{ old_product.quantity }}</span>
              <span :class="{'font-bold': min_history === parseInt(old_product.final_price.match(/\d/g).join(''))}">Prezzo finale: {{
                  old_product.final_price
                }}<span
                    class="text-xs">/{{ history_product.base_uom }}</span></span>
            </div>
            <span v-if="min_history === parseInt(old_product.final_price.match(/\d/g).join(''))"
                  class="text-availability-0 text-sm">Miglior prezzo <i class="fa-solid fa-check text-md"></i></span>
          </div>
        </div>
      </template>
    </slideover>
    <div class="h-md"></div>
  </div>
</template>

<script>
import OrderProductsLine from "./order-products-line.vue";
import _ from "lodash"
import Slideover from "../../components/slideover.vue";

export default {
  name: "order-products",
  components: {Slideover, OrderProductsLine},
  props: {
    products: null,
    changeStep: null,
    discounts: {},
    customer_id: null,
    order_id: null,
    load: null,
    pricing: null,
    from: null,
  },
  data: () => ({
    product_search: {
      sku: null,
      quantity: null,
      discount: null
    },
    product_searched: null,
    loading: false,
    history: null,
    history_product: null,
    min_history: 0
  }),
  mounted() {
    this.product_search.discount = this.$attrs.order_discount;
    if (!this.$props.discounts.filter((v) => v === this.product_search.discount).length) {
      this.$props.discounts.push(this.product_search.discount);
      this.$props.discounts.sort();
    }
    this.$emitter.on('open_history', this.loadHistory)
  },
  computed: {
    forward: function () {
      return this.$props.products && this.$props.products.length > 0
    }
  },
  methods: {
    search_product() {
      if(this.product_search.sku)
      axios.get('/customer/' + this.$props.customer_id + '/product/' + this.product_search.sku + '/show', {
        params: {
          quantity: this.product_search.quantity ?? 1,
          discount: this.product_search.discount,
          replacement: false
        }
      })
          .then(response => {
            this.product_searched = response.data
          }).finally(() => this.loading = false)
    },
    debounce: _.debounce(function () {
      if(this.product_search.sku) this.search_product();
    }, 500),
    async update_item(id, quantity, discount, overwrite = true) {
      let l = this.$loading.show();

      try {
        (await axios.put('/ordini/orders/' + this.$props.order_id + '/products/' + id,
            {quantity: quantity, discount: discount, overwrite: overwrite}));
        this.product_searched = null;
        this.product_search = {
          sku: null,
          quantity: null,
          discount: this.product_search.discount
        };
      } catch (e) {
        this.product_searched = null;
        this.product_search = {
          sku: null,
          quantity: null,
          discount: this.product_search.discount
        };
        this.$toast.error("Quantità richiesta non disponibile");
      }
      await this.$props.load();
      l.hide();
    },
    async loadHistory(product) {
      this.history_product = product;
      this.$refs.slideover.open();
      this.$refs.slideover.set_loading(true);
      try {
        this.history = (await axios.get('/customer/' + this.$props.customer_id + '/product/' + this.history_product.id + '/history/show')).data;
        if (this.history && this.history.length) {
          this.min_history = Math.min(Math.min(...this.history.map((a) => parseInt(a.final_price.match(/\d/g).join('')))), parseInt(this.history_product.unit_price.match(/\d/g).join('')));
        } else this.min_history = parseInt(this.history_product.unit_price.match(/\d/g).join(''));
        this.$refs.slideover.set_loading(false);
      } catch (e) {
        this.$toast.error(e.response.data.message);
        this.$refs.slideover.set_loading(false);
        this.$refs.slideover.close();
      }
    },
    async loadHistoryData() {
      this.history = (await axios.get('/customer/' + this.$props.customer_id + '/product/' + this.history_product.id + '/history/show')).data;
    }
  },
  watch: {
    'product_search.sku': function () {
      if (this.product_search.sku) this.product_search.quantity = 1;
      else {
        this.product_search.quantity = null;
        this.product_searched = null;
        return;
      }
      this.loading = true
      this.debounce();
    },
  }
}
</script>

<style scoped>

</style>
