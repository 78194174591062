<template>
  <div class="rounded">
    <div class="bg-white w-full px-1.5" id="bar">
      <p class="sm:container text-gray-dark text-md pt-2">Agenti</p>
    </div>
    <div class="bg-white z-10 text-gray-dark text-xs p-1.5 space-x-3 w-full" id="header">
      <div class="flex flex-col space-y-1.5 w-full sm:container">
        <div class="flex w-full items-center space-x-2">
          <div class="flex flex-grow bg-gray-light items-center pr-1 space-x-1 rounded">
            <input class="border-0 bg-gray-light" placeholder="Cerca agente" type="search" v-model="search">
            <i class="fa-solid rounded pr-1 fa-magnifying-glass cursor-pointer"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="lg:container mt-2 p-1.5">
      <div class="bg-white border border-thin border-gray-light rounded-xl relative">
        <div v-if="loading"
             class="absolute left-0 top-0 right-0 bottom-0 bg-smoke rounded flex items-center justify-center">
                    <span class="text-white text-md">Caricamento <i
                        class="fas text-base fa-circle-notch animate-spin"></i></span>
        </div>
        <table class="table-auto min-w-full h-full">
          <tr class="text-gray-dark">
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light rounded-tl">ID</th>
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Nome</th>
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Totale  ({{ year }})</th>
            <th class="font-normal text-center px-1.5 py-1.5 bg-gray-light rounded-tr">Azioni</th>
          </tr>
          <TransitionGroup tag="tbody"
                           enter-active-class="duration-700 ease-in-out"
                           enter-from-class="transform-all opacity-0"
                           enter-to-class="opacity-100"
                           leave-active-class="duration-100 ease-in"
                           leave-from-class="opacity-100"
                           leave-to-class="transform-all opacity-0"
          >
          <tr class="border-b border-b-thin border-gray-light text-sm bg-white"
              v-for="customer in customersFiltered" :key="customer.id">
            <td class="p-1.5">{{ customer.id }}</td>
            <td class="p-1.5">
              <div class="flex flex-col">
                <span>{{ customer.company_name }}</span>
              </div>

            </td>
            <td class="p-1.5 whitespace-nowrap">
              {{ customer.total_orders }}
            </td>
            <td class="p-1.5 text-center flex h-full items-center justify-center text-base">
              <router-link :to="{name: 'agents.show', params: {id: customer.id}}"
                           class="text-primary-light hover:text-primary"><i
                  class="fas fa-arrow-right"></i>
              </router-link>
            </td>
          </tr>
          </TransitionGroup>
        </table>
      </div>
    </div>
    <slideover ref="filter" title="Filtri">
      <template v-slot:content>
        <ic v-if="is_super_agent">
          <label for="agent">Agenti</label>
          <select v-model="filters.sales_agent_id" id="agent">
            <option :value="null">Tutti</option>
            <option v-for="agent in agents" :value="agent.id">{{ agent.company_name }}</option>
          </select>
        </ic>
      </template>
    </slideover>
    <div class="h-[7rem] w-full xl:hidden"></div>
    <navbar></navbar>
  </div>
</template>

<script>
import Navbar from "../../components/navbar.vue";

export default {
  name: "agents-index",
  components: {Navbar},
  data: () => ({
    customers: {
      values: []
    },
    search: null,
    loading: false,
    selected_customer: null,
    is_super_agent: false,
    exit: false,
    agents: [],
    types: [
      {value: null, label: 'Tutti'},
      {value: 1, label: 'Solo Attivi'},
      {value: 2, label: 'Solo Inattivi'},
    ],
    filters: {
      sales_agent_id: null,
      type: null,
    },
    year: null,
  }),
  methods: {
    async load() {
      this.loading = true;
      this.customers.values = (await axios.get('/agents')).data;
      this.loading = false;
    },
    open_warning_modal(customer) {
      this.selected_customer = customer;
      this.$refs.warning_modal.open();
    },
    async open_filter_slideover() {
      this.$refs.filter.open();
      let tmp = localStorage['is_super_agent'] === 'true';
      if (tmp && !this.agents.length) {
        this.$refs.filter.set_loading(true);
        this.agents = (await axios.get('/sales-agent')).data;
        this.$refs.filter.set_loading(false);
      }
    }
  },
  async mounted() {
    let header = document.getElementById('header');
    let sticky = header.offsetTop;
    let bar = document.getElementById('bar');
    window.onscroll = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add('sticky');
        header.classList.add('top-0');
        bar.classList.remove('top-0');
      } else {
        bar.classList.add('top-0');
        header.classList.remove('sticky');
        header.classList.remove('top-0');
      }
    }
    if (typeof localStorage['is_super_agent'] === 'undefined') {
      this.loading = true;
      localStorage['is_super_agent'] = (await axios.get('/super-agent')).data;
      this.loading = false;
    }
    this.is_super_agent = localStorage['is_super_agent'] === 'true';
    this.year = (new Date()).getFullYear();
    await this.load();
  },
  computed: {
    customersFiltered: function () {
      let tmp = this.customers.values.slice(0);
      let tmp2 = this.search ? tmp.filter(v => (v.id + '').includes(this.search.toLowerCase()) || v.company_name.toLowerCase().includes(this.search.toLowerCase())) : tmp;
      return tmp2;
    },
  },
  beforeDestroy() {
    window.onscroll = () => null
  }
}
</script>

<style scoped>

</style>
