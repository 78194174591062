<template>

    <div class="flex items-center space-x-2">
        <span class="text-sm text-gray-dark">Step {{step}} di 3</span>
        <button class="h-2 w-2 rounded-full" @click="changeStep(1)" :class="{'outline outline-primary-light outline-offset-2' : step === 1, 'bg-gray-light' : step < 1, 'bg-primary' : step >= 1}"></button>
        <button class="h-2 w-2 rounded-full" @click="changeStep(2)" :class="{'outline outline-primary-light outline-offset-2' : step === 2, 'bg-gray-light' : step < 2, 'bg-primary' : step >= 2}"></button>
        <button class="h-2 w-2 rounded-full" @click="changeStep(3)" :disabled="!forward" :class="{'outline outline-primary-light outline-offset-2' : step === 3, 'bg-gray-light' : step < 3, 'bg-primary' : step >= 3}"></button>
    </div>

</template>

<script>
export default {
    name: "step-component",
    props:{
        step: null,
        forward: false,
        changeStep: null
    }
}
</script>

<style scoped>

</style>
