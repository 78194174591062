<template>
  <div class="mt-2 p-1.5 lg:container">
    <div>
      <div class="flex justify-between mt-2 mb-1">
        <span class="text-md text-gray-dark">Sedi</span>
        <button @click="create"
                class="btn btn-primary text-xs flex items-center justify-center">
          <i class="fas fa-plus"></i></button>
      </div>
      <div class="bg-white border border-thin border-gray-light rounded relative overflow-x-auto">
        <div v-if="loading"
             class="absolute left-0 top-0 right-0 bottom-0 bg-smoke rounded flex items-center justify-center">
          <span class="text-white text-md">Caricamento <i class="fas text-base fa-circle-notch animate-spin"></i></span>
        </div>

        <table class="table-auto min-w-full">
          <tr class="text-gray-dark">
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light rounded-tl">Destinatario</th>
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Indirizzo</th>
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Città</th>
            <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Nazione</th>
            <th class="font-normal text-center px-1.5 py-1.5 bg-gray-light rounded-tr"></th>
          </tr>
          <tr v-for="branch in branches" class="hover:bg-gray-extralight transition">
            <td class="px-1.5 text-sm py-1.5">{{ branch.recipient }}</td>
            <td class="px-1.5 text-sm py-1.5">{{ branch.address }}</td>
            <td class="px-1.5 text-sm py-1.5">{{ branch.city }}</td>
            <td class="px-1.5 text-sm py-1.5">{{ branch.country_code }}</td>
            <td class="px-1.5 text-base py-1.5">
              <button @click="select(branch.id)"><i class="fas fa-arrow-right"></i></button>
            </td>
          </tr>
        </table>
      </div>

      <slideover ref="slideover" :title="branch.id ? 'Modifica indirizzo' : 'Nuovo indirizzo'"
                 :actions="slideover_actions">
        <template v-slot:content>
          <div class="grid grid-cols-3 gap-1.5">
            <ic c="col-span-3" :e="branch_errors.recipient">
              <label>Destinatario</label>
              <input type="text" v-model="branch.recipient">
            </ic>
            <ic :e="branch_errors.country_code">
              <label>Nazione</label>
              <select v-model="branch.country_code">
                <option :value="id" v-for="({id, label}) in country_codes">{{ label }}</option>
              </select>
            </ic>
            <ic :e="branch_errors.address" c="col-span-2">
              <label>Indirizzo</label>
              <input type="text" v-model="branch.address">
            </ic>
            <ic :e="branch_errors.city">
              <label>Città</label>
              <input type="text" v-model="branch.city">
            </ic>
            <ic :e="branch_errors.zip_code">
              <label>CAP</label>
              <input type="text" v-model="branch.zip_code">
            </ic>
            <ic :e="branch_errors.province">
              <label>Provincia</label>
              <input type="text" v-model="branch.province">
            </ic>
            <ic :e="branch_errors.email" c="col-span-3">
              <label>Email</label>
              <input type="email" v-model="branch.email">
            </ic>
            <ic :e="branch_errors.phone" c="col-span-3">
              <label>Telefono</label>
              <input type="tel" v-model="branch.phone">
            </ic>
            <ic :e="branch_errors.notes" c="col-span-3">
              <label>Note</label>
              <textarea v-model="branch.notes"></textarea>
            </ic>
            <ic :e="branch_errors.branch_type">
              <div class="space-y-1 col-span-3">
                <p class="uppercase text-xs text-gray-dark">Tipo sede</p>
                <div class="flex items-center" v-for="type in branch_types.filter(t => t.id > 0)">
                  <input type="checkbox" @change="branch['branch_type'] = branch.branch_type ^ type.id"
                         :checked="branch.branch_type & type.id">
                  <span class="mb-0 ml-1">{{ type.description }}</span>
                </div>
              </div>
            </ic>
          </div>
        </template>
      </slideover>
      <modal ref="delete_branch_modal">
        <div class="flex flex-col space-y-1.75 p-1">
          <span class="text-md font-bold text-primary-light">Attenzione</span>
          <span class="text-gray-dark">Sei sicuro di voler eliminare questo indirizzo?</span>
          <span class="text-gray-dark">Premendo <span class="text-primary-light">Elimina</span> l'indirizzo verrà eliminato.</span>
          <div class="flex w-full justify-end">
            <div class="flex space-x-2">
              <button class="btn btn-outline-primary" @click="$refs.delete_branch_modal.close()">Annulla</button>
              <button class="btn btn-secondary" @click="destroy">Elimina</button>
            </div>
          </div>
        </div>
      </modal>
    </div>

  </div>

</template>

<script>

export default {
  name: "branches",
  props: ['id'],
  computed: {
    slideover_actions: ctx => ctx.branch.id
        ? [{name: 'Cancella', callback: () => ctx.$refs.delete_branch_modal.open(), class: 'btn-secondary'}, {
          name: 'Aggiorna',
          callback: ctx.update,
          class: 'btn-primary'
        }]
        : [{name: 'Crea indirizzo', callback: ctx.store, class: 'btn-primary'}]
  },
  methods: {
    create() {
      this.branch = {};
      this.$refs.slideover.open();
    },
    async select(id) {
      let loading = this.$loading.show();
      try {
        this.branch = (await axios.get('/clienti/branches/' + id)).data;
        this.$refs.slideover.open();
      } catch (e) {
      }
      loading.hide();
    },
    async load() {
      this.loading = true;
      this.branches = (await axios.get('/clienti/customers/' + this.id + '/branches')).data;
      this.country_codes = (await axios.get('/clienti/country_codes')).data;
      let baseURL = null;
      //if (import.meta.env.VITE_ENVIRONMENT || 'development' === 'development') baseURL = 'http://localhost:8000';
      baseURL = 'https://led-italia.it';
      this.branch_types = (await axios({
        url: '/bender2/enum',
        baseURL: baseURL,
        method: 'GET',
        params: {
          enum: 'App\\Domain\\Customer\\Enums\\BranchTypeEnum'
        }
      })).data;
      this.loading = false;
    },
    async store() {
      this.$refs.slideover.set_loading(true);
      try {
        let id = (await axios.post('/clienti/customers/' + this.id + '/branches', this.branch)).data.id;
        await this.select(id);
        await this.load();
        this.$toast.success('Indirizzo creato correttamente');
      } catch (e) {
        if (e.response) {
          if (e.response.data)
            if (e.response.data.errors)
              this.branch_errors = e.response.data.errors;
            else if (e.response.data.message)
              this.$toast.error(e.response.data.message);
        } else this.$toast.error("Il cliente ha già una sede legale");
        this.$refs.slideover.set_loading(false);
      }
      this.$refs.slideover.set_loading(false);
    },
    async update() {
      this.$refs.slideover.set_loading(true);
      try {
        await axios.put('/clienti/branches/' + this.branch.id, this.branch);
        await this.load();
        this.$toast.success('Indirizzo aggiornato correttamente');
        this.$refs.slideover.set_loading(false);
      } catch (e) {
        if (e.response) {
          if (e.response.data)
            if (e.response.data.errors)
              this.branch_errors = e.response.data.errors;
            else if (e.response.data.message)
              this.$toast.error(e.response.data.message);
        } else this.$toast.error("Il cliente ha già una sede legale");
        this.$refs.slideover.set_loading(false);
      }

    },
    async destroy() {
      this.$refs.slideover.set_loading(true);
      try {
        await axios.delete('/clienti/branches/' + this.branch.id);
        this.$toast.success('Indirizzo cancellato correttamente');
        this.$refs.slideover.set_loading(false);
        this.$refs.delete_branch_modal.close();
        this.$refs.slideover.close(true);
        await this.load();
      } catch (e) {
      }
    },
    create_ticket() {
      this.$emitter.emit('open_ticket', {
        flag: 1,
        ticket_class: 'App\\Domain\\Customer\\Models\\Customer',
        id: this.$props.id
      });
    },
  },
  watch: {
    branch() {
      this.branch_errors = {};
    }
  },
  data: () => ({
    loading: true,
    branches: [],
    branch_types: [],
    country_codes: [],
    branch: {},
    branch_errors: {},
    customer: null,
  }),
  async mounted() {
    let l = this.$loading.show();
    try {
      this.customer = (await axios.get('/clienti/customers/' + this.$props.id)).data;
      l.hide();
    } catch (e) {
      this.$toast.error(e.response.data.message || e);
      l.hide();
      this.$router.push({name: 'customers.index'});
    }
    await this.load();
  }
}
</script>
