<template>
    <div :class="[c, {'invalid-input': has_error}]">
        <slot></slot>
        <span class="text-secondary text-sm ml-1" v-if="has_error">{{ e.join(', ') }}</span>
    </div>
</template>

<script>
export default {
    name: "ic",
    props: {
        c: String,
        e: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        has_error() {
            return this.e && this.e.length > 0;
        }
    }
}
</script>