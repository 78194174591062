<template>
    <div>
        <div class="flex justify-between mt-4 mb-2">
            <p class="text-md text-gray-dark">Carrelli aperti</p>
            <div class="flex space-x-1.5">
                <a href="#" class="btn btn-primary h-auto px-2 py-1.5 text-xs"><i class="fas fa-download"></i></a>
            </div>
        </div>
        <div class="bg-white border border-thin border-gray-light rounded">
            <table class="w-full">
                <tr class="text-gray-dark">
                    <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light rounded-tl">ID</th>
                    <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Tipo</th>
                    <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Utente</th>
                    <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Prodotti</th>
                    <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Totale netto</th>
                    <th class="font-normal text-center px-1.5 py-1.5 bg-gray-light rounded-tr">Azioni</th>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "carts"
}
</script>