<template>
  <slideover ref="slideover" v-if="visible" title="Crea ticket" @close="visible = false">
    <template v-slot:content>
      <div class="space-y-2 h-full flex flex-col">
        <ic :e="errors.type">
          <label>Tipo ticket</label>
          <select v-model="ticket.type">
            <option :value="id" v-for="({id, description}) in ticket_types">{{ description }}</option>
          </select>
        </ic>
        <ic :e="errors.title">
          <label>Titolo</label>
          <input type="text" v-model="ticket.title">
        </ic>
        <ic c="flex-grow flex flex-col" :e="errors.message">
          <label>Messaggio</label>
          <textarea class="resize-none flex-grow" v-model="ticket.message"></textarea>
        </ic>
      </div>
    </template>
    <template v-slot:actions>
      <div class="flex justify-end w-full">
        <div class="btn btn-primary" @click="store()">Invia ticket</div>
      </div>
    </template>
  </slideover>
</template>

<script>
export default {
  name: "create_ticket",
  watch: {
    visible: {
      immediate: true,
      handler(new_value) {
        if (!this.visible) this.router = null;
        this.$nextTick(() => {
          new_value ? this.$refs.slideover.open() : (this.$refs.slideover ? () => {
            this.$refs.slideover.close();
          } : null)
        })
        if (!this.visible && !this.first) this.close();
      }
    }
  },
  methods: {
    open(obj) {
      this.ticket = {
        type: obj.flag || 1,
        title: obj.title || null,
        message: obj.message || null,
        subject_type: obj.ticket_class || null,
        subject_id: obj.id || null,
      };
      this.errors = {};
      this.visible = true;
    },
    async store() {
      this.$refs.slideover.set_loading(true);
      let ticket = null;
      try {
        ticket = (await axios.post('/assistenza/tickets', this.ticket)).data;
        await this.$router.push({name: 'tickets.show', params: {id: ticket.id}});
        this.$refs.slideover.set_loading(false);
        this.$refs.slideover.close();
      } catch (e) {
        this.errors = e.response.data.errors;
        this.$refs.slideover.set_loading(false);
      }
    },
    close() {
      document.querySelector('body').style.overflowY = null;
    }
  },
  data: () => ({
    visible: false,
    ticket_types: [],
    ticket: {
      type: null,
      title: null,
      message: null,
      subject_type: null,
      subject_id: null,
    },
    errors: {},
    router: null,
    first: true,
  }),
  async mounted() {
    this.$emitter.on('open_ticket', this.open);
    let baseURL = null;
    //if (import.meta.env.VITE_ENVIRONMENT || 'development' === 'development') baseURL = 'http://localhost:8000';
    baseURL = 'https://led-italia.it';
    this.ticket_types = (await axios({
      url: '/bender2/enum',
      baseURL: baseURL,
      method: 'GET',
      params: {
        enum: 'App\\Domain\\Ticketing\\Enums\\TicketTypeEnum'
      }
    })).data;

    this.first = false;

  }
}
</script>
