<template>
    <div>
        <div v-if="tracking.carrier" :id="'tracking_details-' + tracking.ddt_id">
            <div class="flex flex-col lg:flex-row justify-between text-sm">
                <span><b>DDT</b> {{ tracking.ddt_id }}</span>
                <button class="text-sm text-right" @click="open_modal">Ulteriori informazioni <i
                    class="fas fa-arrow-right text-xs"></i></button>
            </div>
        </div>

        <div class="flex flex-col lg:flex-row lg:justify-between lg:p-4 lg:pb-2 lg:my-0 my-2 relative">
            <div class="flex flex-col lg:flex-row" :class="index !== 0 ? 'w-full': null"
                 v-for="(step, index) in tracking.steps">
                <div v-if="step !== tracking.steps[0]"
                     class="items-center justify-center flex-grow flex w-1.75 lg:w-auto">
                    <div class="w-0.5 h-2 lg:h-1 lg:w-full"
                         :class="step.active ? 'bg-primary-light' : 'bg-gray-light'"></div>
                </div>

                <div class="flex items-center relative">
                    <span
                        class="text-xs font-bold text-gray-dark hidden lg:block absolute -top-1/3 -left-full -right-full text-center">{{
                            shipment[step.type]["title"]
                        }}</span>
                    <div class="flex flex-col justify-center">
                        <div v-if="step !== tracking.steps[0]"
                             class="items-center justify-center flex-grow flex lg:hidden">
                            <div class="w-0.5 h-2 lg:h-1 lg:w-full"
                                 :class="step.active ? 'bg-primary-light' : 'bg-gray-light'"></div>
                        </div>
                        <div v-else class="items-center justify-center flex-grow flex lg:hidden">
                            <div class="w-0 h-2 lg:h-1 lg:w-full"
                                 :class="step.active ? 'bg-primary-light' : 'bg-gray-light'"></div>
                        </div>
                        <div
                            class="lg:w-4 lg:h-4 w-1.75 h-1.75 lg:w-sm lg:h-sm flex rounded-full items-center justify-center text-sm lg:text-md"
                            :class="step.active ? 'bg-primary-light text-white' : 'bg-gray-light'">
                            <span class="lg:block hidden"><i :class="step.icon"></i></span>
                        </div>

                    </div>
                    <div
                        class="flex items-center space-x-1 lg:space-x-0 text-xs font-bold lg:hidden pl-1 absolute top-1/4 left-2">
                        <div class="flex flex-col justify-center">
                            <div
                                class="w-4 h-4 lg:w-sm lg:h-sm flex rounded-full items-center justify-center text-sm lg:text-md lg:hidden"
                                :class="step.active ? 'bg-primary-light text-white' : 'bg-gray-light'">
                                <i :class="step.icon"></i>
                            </div>
                        </div>
                        <span class="flex flex-col">
                        <span
                            class="text-sm text-gray-dark">{{
                                shipment[step.type]["title"]
                            }}</span>
                        <span
                            class="font-normal text-gray-dark">{{
                                shipment[step.type]["description"]
                            }}</span>
                    </span>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "order-tracking",
    props: ['tracking'],
    methods: {
        open_modal() {
            this.$emitter.emit('open_tracking_modal', JSON.parse(JSON.stringify(this.$props.tracking)));
        }
    },
    data: () => ({
        shipment: {
            reception: {
                title: "In ricezione",
                description: "Abbiamo ricevuto l'ordine.",
            },
            preparing: {
                title: "In preparazione",
                description: "Stiamo preparando l'ordine.",
            },
            shipped: {
                title: "Spedito",
                description: "L'ordine è stato affidato al corriere.",
            },
            transit: {
                title: "In transito",
                description: "L'ordine è in arrivo."
            },
            delivering: {
                title: "In consegna",
                description: "L'ordine è in consegna.",
            },
            delivered: {
                title: "Consegnato",
                description: "L'ordine è stato consegnato."
            }
        }
    })
}
</script>

<style scoped>

</style>
