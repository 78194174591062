<template>
    <select v-model="target" :class="select_class">
        <option :value="null" v-if="nullable">Nessuna selezione</option>
        <option :value="typeof id === 'string' ? v[id] : id(v)" v-for="v in values">{{ typeof label === 'string' ? v[label] : label(v) }}</option>
    </select>
</template>

<script>
export default {
    name: "enum",
    props: {
        value: String | Number | null,
        enum: {
            type: String,
            required: true
        },
        nullable: {
            type: Boolean,
            default: false
        },
        id: {
            type: String | Function,
            default: 'id'
        },
        label: {
            type: String | Function,
            default: 'description'
        },
        select_class: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        values: []
    }),
    computed: {
        target: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    async mounted() {
        this.values = (await axios.get(route('bo.enum'), {params: {enum: this.enum}})).data;
        if (!this.nullable && !this.value) this.$emit('input', typeof this.id === 'string' ? this.values[0][this.id] : this.id(this.values[0]));
    }
}
</script>