<template>
  <transition
      name="expand"
      @enter="enter"
      @afterenter="afterEnter"
      @leave="leave"
    >
    <slot/>
  </transition>
  </template>

<script>
export default {
  name: "transitionexpand",
  methods: {
    afterEnter(element) {
      element.style.height = 'auto';
    },
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      const height = getComputedStyle(element).height;
      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  }
}
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition-property: opacity, height;
  transition: height 1s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  opacity: 0;
  height: 0;
}

* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
