<template>
    <div class="rounded p-2 flex flex-col my-2 bg-white space-y-1.5">
        <div class="flex justify-between"><p class="text-gray-dark font-bold text-sm">{{ metadata.name }}</p> <span class="text-xs">Prodotti: {{metadata.products_count}} / {{total_products}}</span></div>
        <div class="flex flex-col md:flex-row w-full md:justify-between md:items-center overflow-hidden space-y-1 md:space-y-0 md:space-x-2">
            <p class="text-gray-dark truncate text-sm">{{ metadata.description }}</p>
            <div class="flex space-x-1">
                <button v-if="metadata.import_value_class" class="uppercase mb-1 rounded px-2 py-1 text-xs font-bold bg-transparent hover:bg-primary hover:text-white text-primary border border-primary" @click="autoupdate">AutoGenera</button>
                <a @click="download"
                   class="uppercase mb-1 rounded px-2 py-0.5 text-sm font-bold bg-transparent hover:bg-primary hover:text-white text-primary border border-primary">
                    Scarica
                </a>
                <input type="file" :id="'metadata-'+metadata.id" class="hidden" @change="uploadFile">
                <label :for="'metadata-'+metadata.id"
                       class="rounded px-2 py-0.5 text-sm font-bold bg-transparent hover:bg-primary hover:text-white text-primary border border-primary cursor-pointer">
                    Carica
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "metadata-show",
    props: ['metadata', 'total_products'],
    data: () => ({
        url: null
    }),
    methods: {
        async uploadFile(e) {
            let file = e.target.files[0];
            if (file) {
                let l = this.$loading.show();
                try {
                    let formData = new FormData();
                    formData.append('input', file);
                    (await axios.post('/metadata/' + this.$props.metadata.id + '/import', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }));
                    this.$toast.success('Dati caricati con successo');
                } catch (error) {
                    this.$toast.error(error.response.data.message);
                }
                l.hide();
            }
        },
        async download() {
            let l = this.$loading.show();
            if (this.url) {
                URL.revokeObjectURL(this.url);
                this.url = null
            }
            let response = (await axios({
                url: '/metadata/' + this.$props.metadata.id + '/export',
                method: 'GET',
                responseType: 'blob',
            })).data;
            this.url = URL.createObjectURL(response);
            l.hide();
            window.open(this.url, '_blank');
        },
        async autoupdate(){
            let l = this.$loading.show();
            try{
                let response = (await axios.post('/metadata/' + this.$props.metadata.id + '/auto-update')).data;
                l.hide();
                this.$emitter.emit('show_report_emit', true)
            }catch(e){
                this.$toast.error(e.response.data.message);
                l.hide()
            }
        }
    },
    computed: {
        export_url: function () {

        }
    },
    beforeUnmount() {
        URL.revokeObjectURL(this.url);
    }
}
</script>

<style scoped>

</style>
