<template>
  <div class="xl:container px-2 mt-2" v-if="product">
    <div class="flex justify-between fixed bg-white inset-x-0 top-0 z-10 text-gray-dark text-xs p-2 space-x-3">
      <div class="flex space-x-3">
        <p class="text-md text-gray-dark cursor-pointer" @click="$router.go(-1)"><i
            class="fa-solid fa-arrow-left"></i></p>
        <h1 class="text-md text-gray-dark capitalize">Prodotti</h1>
      </div>
      <button class="self-end text-md" @click="create_ticket"><i class="fa-solid fa-headset"></i></button>
    </div>


    <div class="h-sm"></div>
    <div class="lg:container flex mb-2 flex-wrap bg-white p-2 rounded">
      <div class="w-full">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-1/3 lg:pr-2">
            <div class="flex justify-between items-center lg:hidden">
              <div class="text-lg font-bold flex items-center space-x-1.5">
                                <span v-if="product.is_new"
                                      class="py-1 px-1.5 rounded text-white bg-primary text-base inline-block">Novità</span>
                <h1 class="text-primary">SKU {{ product.sku }}</h1>
                <span class="text-muted">{{ product.family_code }}</span>
              </div>
              <button class="share-btn text-sm btn h-auto btn-primary-outline p-1">condividi <i
                  class="ml-1 fas fa-share-alt"></i></button>
            </div>
            <h2 class="mt-1 lg:hidden text-gray-dark"><span v-html="product.description_prefix"></span>
              {{ product.description }}</h2>
            <div class="md:px-md lg:px-0 rounded">
              <img v-if="product && product.images && product.images.length" :src="product.images[0].url"
                   class="rounded transition-all image-preview border border-transparent"
                   title=""
                   :alt="product.images[0].description">
            </div>
          </div>
          <div class="w-full lg:w-2/3">
            <div class="hidden lg:block">
              <div class="flex justify-between items-center">
                <div class="text-lg font-bold flex items-center space-x-1.5">
                                    <span v-if="product.is_new"
                                          class="py-1 px-1.5 rounded text-white bg-primary text-base inline-block">Novità</span>
                  <h1 class="text-primary">SKU {{ product.sku }}</h1>
                  <span class="text-muted">{{ product.family_code }}</span>
                </div>
                <button class="share-btn text-sm btn h-auto btn-primary-outline p-1">condividi <i
                    class="ml-1 fas fa-share-alt"></i></button>
              </div>
              <h2 class="mt-2 text-md text-gray-dark"><span v-html="product.description_prefix"></span>
                {{ product.description }}</h2>
            </div>

            <div class="flex rounded flex-wrap">
              <div v-for="(value, name) in product.base_specifications"
                   class="flex flex-col justify-between items-center w-1/4">
                <span class="text-gray text-xs text-center block mt-2 px-1">{{ name }}</span>
                <span
                    class="text-primary text-center font-bold flex h-full items-center">{{
                    value
                  }}</span>
              </div>
            </div>

            <div class="mt-4 items-center">
              <div v-for="(items, name) in product.variations">
                <span class="text-primary font-bold">{{ name }}</span>
                <div class="flex space-x-2 lg:flex-wrap overflow-hidden overflow-x-auto text-gray-dark">
                  <button v-for="item in items" @click="load(item.id)"
                          class="my-1 px-2 border border-transparent rounded hover:bg-primary hover:border-transparent hover:text-white transition-all"
                          :class="item.id === product.id ? 'border-secondary text-secondary bg-white': 'bg-white'">
                    {{ item.name }}
                  </button>
                </div>
              </div>
            </div>

            <div v-if="order_id" class="mt-4">
              <div class="flex flex-col space-y-1.5 w-full p-1.5">
                <div class="flex justify-between mb-1">
                  <div class="flex flex-col flex-grow">
                    <p v-if="loading" class="font-bold text-gray text-md"><i class="fa-solid fa-spinner animate-spin"></i> Caricamento</p>
                    <template v-else-if="product_searched && Object.keys(product_searched).length !== 0">
                      <p class="font-bold text-start text-md mb-1.5" :class="product_searched['availability']['color']" v-html="product_searched.availability.text"></p>
                      <span class="text-gray-dark text-md space-x-2" v-if="product_searched.raw_quantity"> <span v-if="product_searched.raw_quantity[1]">IT: {{
                          Math.max(0, product_searched.raw_quantity[1].quantity) }} {{ product_searched.base_uom || 'pz' }} </span>
                        <span v-if="product_searched.raw_quantity[2]">EU: {{ Math.max(0, product_searched.raw_quantity[2].quantity) }} {{ product_searched.base_uom || 'pz' }}</span> </span>
                      <p class="text-gray mt-3" :class="{'line-through': product_searched.promo_price}">Prezzo di listino {{product_searched.base_price}}/{{product_searched.base_uom}}</p>
                      <p class="text-flags-promo" v-if="product_searched.promo_price">Prezzo Promo {{product_searched.promo_percentage}}% {{product_searched.promo_price}}/{{product_searched.base_uom}} <span class="text-sm">fino al {{product_searched.promo_expire}}</span></p>
                      <p
                         class="text-md text-primary">Prezzo: {{ product_searched.line_price }} <span class="text-gray text-sm">({{product_searched.unit_price}}/{{product_searched.base_uom}})</span></p>
                    </template>
                    <span v-else-if="product_searched" class="text-xs text-secondary mt-1">Prodotto non trovato</span>
                  </div>
                </div>
                <div class="flex space-x-1 w-full">
                  <div class="flex flex-col flex-grow">
                    <label class="text-gray-dark text-xs" for="quantity">Quantità</label>
                    <div class="flex flex-col space-y-1">
                      <input type="text" class="h-5" v-model="product_search.quantity" @focus="$event.target.select()" id="quantity">
                    </div>
                  </div>
                  <div class="flex space-x-1">
                    <div class="flex flex-col flex-grow">
                      <label class="text-gray-dark text-xs" for="discount"><span class="text-xs">Sconto</span></label>
                      <select v-model="product_search.discount" id="discount"
                              class="border-solid border-gray-light bg-transparent min-w-[5rem] h-5">
                        <option v-for="discount in discounts" :value="discount">{{
                            discount
                          }}%
                        </option>
                      </select>
                    </div>
                    <div class="flex flex-col space-y-1">
                      <span class="text-gray-dark text-xs">&#8205;</span>
                      <button
                          @click="update_item(product_searched.id, product_searched.quantity, product_search.discount, true)"
                          :class="{disabled: loading || !product_searched || (Object.keys(product_searched).length === 0) || (Object.keys(product_searched).length !== 0 && product_searched.availability.type > 3)}"
                          class="btn btn-primary cursor-pointer self-end h-5 w-5">+
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="product.raw_quantity" class="mt-5">
              <p class="font-bold text-start text-md mb-1.5" :class="product.refined_availability.color"
                 v-html="product.refined_availability.text"></p>
              <span class="text-gray-dark text-md space-x-2">
                <span v-if="product.raw_quantity[0]">IT: {{ Math.max(0, product.raw_quantity[0].quantity) }} {{ product.base_uom || 'pz' }}</span>
                <span v-if="product.raw_quantity[1]">EU: {{ Math.max(0, product.raw_quantity[1].quantity) }} {{ product.base_uom || 'pz' }}</span>
              </span>
            </div>
            <div v-if="!order_id && product.base_price">
              <p class="text-primary text-lg mt-3" :class="{'line-through': product && product.promo}">Prezzo di listino {{product.base_price}}/{{product.base_uom}}</p>
              <p class="text-flags-promo" v-if="product && product.promo && product_searched">Prezzo Promo {{product.promo.percentage}}% {{product_searched.promo.price}}/{{product.base_uom}} <span class="text-sm">fino al {{product.promo.end_date}}</span></p>
              <p class="text-flags-promo" v-else-if="product && product.promo">Prezzo Promo {{product.promo.percentage}}% {{product.promo.price}}/{{product.base_uom}} <span class="text-sm">fino al {{product.promo.end_date}}</span></p>
            </div>

          </div>
        </div>
        <div class="w-full hidden lg:block">
          <div class="mt-sm w-full space-y-3">
            <div class="w-full">
              <h4 class="text-primary font-bold text-md">Specifiche tecniche</h4>
              <div class="flex flex-wrap mt-2 text-sm">
                <div v-for="(value,name) in product.pre_specifications"
                     class="w-full lg:w-1/2 py-1.5 flex border-b-thin border-gray-light">
                  <b class="w-1/2 block">{{ name }}</b>
                  <span>{{ value }}</span>
                </div>
                <div v-for="(value,name) in product.specifications"
                     class="w-full lg:w-1/2 py-1.5 flex border-b-thin border-gray-light">
                  <b class="w-1/2 block">{{ name }}</b>
                  <span>{{ value }}</span>
                </div>
              </div>
            </div>
            <div class="w-full">
              <h4 class="text-primary font-bold text-md">Packaging</h4>
              <div class="flex flex-wrap mt-2 text-sm">
                <div v-for="(value, name) in product.packaging"
                     class="w-full py-1.5 flex border-b-thin border-gray-light">
                  <b class="w-1/2 block">{{ name }}</b>
                  <span>{{ value }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full lg:hidden">
        <div class="mt-sm w-full space-y-3">
          <div v-if="product.page" class="w-full">
            <div class="lg:pr-4">
              <h4 class="text-primary font-bold text-md mb-1">Maggiori informazioni</h4>
              <div class="prose max-w-full my-2" v-html="product.page">
              </div>
            </div>
          </div>
          <div class="w-full">
            <h4 class="text-primary font-bold text-md">Specifiche tecniche</h4>
            <div class="flex flex-wrap mt-2 text-sm">
              <div v-for="(value,name) in product.pre_specifications"
                   class="w-full lg:w-1/2 py-1.5 flex border-b-thin border-gray-light">
                <b class="w-1/2 block">{{ name }}</b>
                <span>{{ value }}</span>
              </div>
              <div v-for="(value,name) in product.specifications"
                   class="w-full lg:w-1/2 py-1.5 flex border-b-thin border-gray-light">
                <b class="w-1/2 block">{{ name }}</b>
                <span>{{ value }}</span>
              </div>
            </div>
          </div>
          <div class="w-full">
            <h4 class="text-primary font-bold text-md">Packaging</h4>
            <div class="flex flex-wrap mt-2 text-sm">
              <div v-for="(value, name) in product.packaging"
                   class="w-full py-1.5 flex border-b-thin border-gray-light">
                <b class="w-1/2 block">{{ name }}</b>
                <span>{{ value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full mt-sm" v-if="product.attachments && product.attachments.length">
        <h4 class="text-primary font-bold text-md mb-1">Scaricabili</h4>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 mt-3">
          <a v-for="attachment in product.attachments"
             class="flex justify-between items-center p-1 text-gray-dark hover:text-primary"
             :href="attachment.route" target="_blank">
            <p class="">
              <i class="fas pr-1.5 text-xl" :class="attachment.icon"
                 :style="{color: attachment.icon_color}"></i>
              {{ attachment.type }}
            </p>
          </a>
        </div>
      </div>
    </div>
    <div v-if="order_id || product.raw_quantity" class="h-md"></div>
  </div>
</template>

<script>
import _ from "lodash"

export default {
  name: "product",
  props: ['id', 'order_id'],
  data: () => ({
    product: {},
    customer_id: null,
    request: null,
    product_search: {
      quantity: null,
      discount: 50
    },
    product_searched: null,
    order: null,
    discounts: [50,55,60,62],
    loading: false,
    discount: 50
  }),
  methods: {
    async load(id = null) {
      let l = this.$loading.show();
      try {
        this.product = (await axios.get('/prodotti/products/' + (id || this.id))).data;
        if (id) await this.$router.replace({name: 'products.show', params: {id: this.product.id, order_id: this.order_id}});

        if (this.order_id) {
          if (!this.order) {
            let response = (await axios.get('/ordini/orders/' + this.order_id)).data;
            this.order = response.order;
            this.discount = response.discount;
            if(this.order.pricing.natural_person) this.discounts = [];
            if (!this.discounts.filter((v) => v === this.discount).length){
              this.discounts.push(this.discount);
              this.discounts.sort();
            }
            this.customer_id = this.order.customer_id;
          }
          let product_line = (await axios.get('/ordini/orders/' + this.order_id + '/products', {
            params: {product_id: this.product.id}
          })).data;
          this.product_search.discount = product_line && product_line.length ? product_line[0].discount : this.discount;
          this.product_search.quantity = product_line && product_line.length ? product_line[0].quantity : 1 * this.product.sale_lot;
        }
        l.hide();
      } catch (e) {
        this.$toast.error("Prodotto non trovato")
        l.hide();
        await this.$router.go(-1);
      }
    },
    create_ticket() {
      this.$emitter.emit('open_ticket', {
        flag: 4,
        ticket_class: 'App\\Domain\\Catalog\\Models\\Product',
        id: this.$props.id
      });
    },
    search_product() {
      axios.get('/customer/' + this.customer_id + '/product/' + this.product.sku + '/show', {
        params: {
          quantity: this.product_search.quantity,
          discount: this.product_search.discount,
          replacement: false
        }
      })
          .then(response => {
            this.product_searched = response.data
            this.product_search.quantity = this.product_searched.quantity;
          }).finally(() => this.loading = false)
    },
    debounce: _.debounce(function () {
      this.search_product();
    }, 400),
    async update_item(id, quantity, discount, overwrite = true) {
      let l = this.$loading.show();
      try {
        (await axios.put('/ordini/orders/' + this.$props.order_id + '/products/' + id, {
          quantity: quantity,
          discount: discount,
          overwrite: overwrite
        }));
        this.$toast.success("Prodotto aggiunto al carrello")
      } catch (e) {
        this.product_searched = null;
        this.product_search = {
          sku: null,
          quantity: null,
          discount: this.product_search.discount
        };
        this.$toast.error("Quantità richiesta non disponibile");
      }
      l.hide();
    },
  },
  mounted() {
    this.load();
    if (navigator.share) {
      document.querySelectorAll('.share-btn').forEach(button => {
        button.classList.remove('hidden');
        button.addEventListener('click', event => navigator.share({
          title: 'V-TAC Italia',
          text: 'SKU '+ this.product.sku,
          url: this.product.share_url
        }))
      })
    }
  },
  watch: {
    'product_search.quantity': function () {
      if (this.product_searched && this.product_searched.quantity === this.product_search.quantity)
        return;
      this.product_searched = null;
      if (this.product_search.quantity && this.product.sku) {
        this.loading = true;
        this.debounce();
      }
    }
  }
}
</script>

<style scoped>

</style>
