<template>
  <div class="justify-center items-center w-full min-h-[25rem] max-h-[30rem] hidden md:flex">
    <bar v-if="!loading" id="month" :options="chartOptions" :data="chartData"/>
    <p v-else class="text-lg"><i class="fa-solid fa-spinner animate-spin"></i></p>
  </div>
  <div class="justify-center items-center w-full min-h-[25rem] max-h-[30rem] flex md:hidden">
    <bar v-if="!loading" id="month-mobile" :options="chartOptionsMobile" :data="chartData"/>
    <p v-else class="text-lg"><i class="fa-solid fa-spinner animate-spin"></i></p>
  </div>
</template>

<script>
import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from "chart.js"
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
  name: "months_chart",
  components: {Bar},
  props: ['sales_agent_id'],
  data: () => ({
    loading: true,
    chartOptionsMobile: {
      maintainAspectRatio: false,
      responsive: true,
      indexAxis: 'y',
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true
        }
      },
    },
    chartOptions: {
      responsive: true,
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true
        }
      }
    },
    chartData: {
      labels: [],
      datasets: [
        {
          label: 'Web',
          backgroundColor: 'rgb(255, 99, 132)',
          data: []
        },
        {
          label: 'Agente',
          backgroundColor: 'rgb(54, 162, 235)',
          data: []
        }
      ]
    },
  }),
  async mounted() {
    let params = this.$props.sales_agent_id ? new URLSearchParams([['sales_agent_id', this.$props.sales_agent_id]]) : null;
    let data = (await axios.get('/charts/months_trend', {params})).data;
    this.chartData.labels = data.labels;
    data.data.map((v) => v.total_web).forEach((v) => this.chartData.datasets[0].data.push(v));
    data.data.map((v) => v.total_agent).forEach((v) => this.chartData.datasets[1].data.push(v));
    this.loading = false;
  }
}
</script>

<style scoped>

</style>
